(function($, B) {
    'use strict';

    $.widget(
        'bajt.offerItem',
        $.extend(true, {}, B.formCollectionItem, {
            options: {
                ecn: 'OfferItems',
                formFields: {
                    color: {
                        options: {
                            dictionary: true,
                            disp: {
                                type: 'dic.s',
                            }
                        }
                    },
                    name: {
                        options: {
                            navi: 1
                        }
                    },
                    description: {
                        options: {
                            navi: 2
                        }
                    }
                }
            },
            getUnique:function(){
                return this.field('color').value().s;  
            }
        })
    );

    $.widget(
        'bajt.offerItemsCollection',
        $.extend(true, {}, B.formCollection, {
            options: {
                ecn: 'OfferItems',
            },  
            _createBasicOptions:function(){
                $.extend(true, this.options, { 
                    nameGenerator : this.getEntitySetting('nameGenerator')
                });
                return B.formCollection._createBasicOptions.call(this);
            },         
            _genText:function(generator, values){
                return B.genNumber(generator, '', { entity: values, fields: this.options.ecn });
            },
            _genName:function(values){
                return B.obj.is(this.options['nameGenerator'])
                    ? this._genText(this.options['nameGenerator'], values)
                    : 'Parapet ' + values.color.s;
            },
            _itemImportData: function(inData) {
                var out={
                    color : inData.color
                };
                out['name']=this._genName(out);
                return out;
            }
        })
    );

    $.widget(
        'bajt.offerItemsGenerateForm',
        $.extend(true, {}, B.basicForm, {
            options: {
                actions: ['generate'],
                formFields: {
                    color: {
                        options: {
                            widget: {
                                type: 'multiselect'
                            }
                        }
                    },
                    items: {
                        fieldWidget: 'offerItemsCollection',
                        options:{
                            ecn: 'OfferItems',
                            autoNew: false,
                            focusField: 'name',
                            unique: true,
                            itemOptions:{
                                widget: 'offerItem'
                            }
                       } 
                    }
                },
                fieldOptions: {
                    items : function(){
                        return { entitySettings : this.options.entitySettings };
                    }
                }
            },
            _eActionGenerate:function(e, data){
                stopTrigger(e);
                this._generateItems();
            },
            _generateItems: function(e) {
                var o = this.options,
                    colors = this.field('color').value(),
                    colorsDic = this.getDictionary('Colors');
                if (B.obj.is(colors)) {
                    var items=[];
                    for (var i in colors) {
                        items.push({
                            color: B.dic.from(colorsDic, colors[i])
                        });
                    }
                    this.field('items').import(items);
                }
            }
        })
    );

    $.fn.initFormOfferItemsGenerate = function() {
        var $form = $(this).find('form[data-form=offeritemsgenerate]');
        if (B.obj.is$($form)) {
            $form.offerItemsGenerateForm();
        }
    };

})(jQuery, Bajt);