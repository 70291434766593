






$('#btnDel').click(function() {
    // confirmation
    Bajt.checkCode('app.js', 'click', 12);
    if (confirm('Are you sure you wish to remove this section? This cannot be undone.')) {
        var num = $('.clonedInput').length;
        // how many 'duplicatable' input fields we currently have
        $('#entry' + num).slideUp('slow', function() {
            $(this).remove();
            // if only one element remains, disable the 'remove' button
            if (num - 1 === 1) {
                $('#btnDel').attr('disabled', true);
            }
            // enable the 'add' button
            $('#btnAdd')
                .attr('disabled', false)
                .prop('value', 'add section');
        });
    }
    return false;
    // remove the last element

    // enable the 'add' button
    //        $('#btnAdd').attr('disabled', false);
});




