$(document).ready(function() {
    //    $('.filterbar').filterbar();
    //        console.log('ready-document');
    var $body = $('body');
    $body.find('.scroll-x, .scroll, .table-responsive').calcScrollChildrenWidth();
    // each(function() {
    //     var scrollWidth = this.scrollWidth,
    //         clientWidth = this.clientWidth;
    //     if (scrollWidth > clientWidth) {
    //         $(this)
    //             .children()
    //             .each(function() {
    //                 var calcWidth = this.clientWidth + scrollWidth - clientWidth;
    //                 $(this).css({
    //                     width: calcWidth,
    //                     'max-width': calcWidth
    //                 });
    //             });
    //     }
    // });

    B.app.initContent($body);

    B.events.init('window', 'datatable');
    B.events.init('body');
    B.events.init('body', 'app');
    B.events.init('body', 'form');
    B.events.init('body', 'datatable');


    // $body
        // .on('click', '.btn[data-toggle=ajax][href!="#"]', function(e) {
        //     stopTrigger(e);
        //     Bajt.app.ajaxLoad.call(this);
        // })
        // .on('click', '[data-menu=ajax] .nav-link[href!="#"]', function(e) {
        //     var $link = $(this),
        //         // url = $link.attr('href'),
        //         target = $link.attr('target');
        //     if (['_blank', '_self'].indexOf(target) >= 0) {
        //         return;
        //     }
        //     stopTrigger(e);
        //     Bajt.app.ajaxLoad.call(this, null, function(){
        //         var 
        //             $item = $link.parent(),
        //             $dropdownMenu = $item.closest('.dropdown-menu'),
        //             $dropdown = $dropdownMenu.closest('.dropdown'),
        //             $menu = $item.closest('[data-menu=ajax]');
        //         $menu.find('.active').removeClass('active');
        //         $item.addClass('active');
        //         if (Bajt.obj.is$($dropdown)) {
        //             $dropdownMenu.removeClass('show');
        //             $dropdown.removeClass('show');
        //             $dropdown.find('[aria-expanded=true]').attr('aria-expanded', false);
        //         }
        //     });
        //         // $item.dropdown('toggle');
        //         // $mainWrapper.load(
        //         //     url,
        //         //     {
        //         //         view: 'ajax'
        //         //     },
        //         //     function(responseText, textStatus, jqXHR) {
        //         //         $loader.remove();
        //         //         if(jqXHR.status == 403){
        //         //             $('body').trigger('logout', jqXHR);
        //         //         }else if (textStatus === 'error') {
        //         //             var $modal = $('#my_modal');
        //         //             if(B.obj.is$($modal)) {
        //         //                 $modal.find('.modal-content').html(responseText);
        //         //                 $modal.modal('show');
        //         //             } else {
        //         //                 alert(responseText);
        //         //             }
        //         //         } else {
        //         //             $menu.find('.active').removeClass('active');
        //         //             $item.addClass('active');
        //         //             if (Bajt.obj.is$($dropdown)) {
        //         //                 $dropdownMenu.removeClass('show');
        //         //                 $dropdown.removeClass('show');
        //         //                 $dropdown.find('[aria-expanded=true]').attr('aria-expanded', false);
        //         //             }
        //         //             window.history.replaceState('', '', url);
        //         //             document.title = $mainWrapper.find('#page_title').data('title');
        //         //             getAppSettings();
        //         //             $mainWrapper.initContent();
        //         //             $mainWrapper.initModals();
        //         //         }
        //         //     }
        //         // );
        // })        
        // .on('logout', function(e, jqXHR){
        //     console.log(jqXHR);
        //     var $modal = $('#my_modal'),
        //         data = jqXHR.responseJSON || JSON.parse(jqXHR.responseText);
        //     $modal.showContent(data.html);
        // })
        // .on('focus', '.form-group.is-field-tooltip', function(e){
        //     var $this=$(this);
        //     $this.find('.field-tooltip').slideDown('fast');
        // })
        // .on('blur', '.form-group.is-field-tooltip', function(e){
        //     var $this=$(this);
        //     $this.find('.field-tooltip').slideUp('fast');
        // })
        // .on('click', '[data-toggle-details]', function() {
        //     var $this = $(this),
        //         type = String($this.data('toggle-details')),
        //         target = $this.data('target'), 
        //         expanded = $this.attr('data-expanded') === '1' ? true : false,
        //         fn = expanded ? 'slideUp' : 'slideDown' ,
        //         _fnCallBack = expanded 
        //             ? function(){
        //                 $(this).removeClass('show');
        //             }
        //             : function(){
        //                 $(this).addClass('show');
        //             },
        //         $container;    

        //     switch(type){
        //         case 'all':
        //             $container = $body;
        //         break;
        //         case 'childs':
        //             $container = $this;
        //         break;
        //         default:
        //             $container = $this.closest(type);
        //     }
        //     if(type === 'childs'){
        //         $container.children(target)[fn](_fnCallBack);
        //     }else{
        //         $container.find(target)[fn](_fnCallBack);
        //     }
        //     // $this.attr('data-expanded', !expanded);
        //     var ll=$container.find('[data-target="' + target + '"]');
        //     $container.find('[data-target="' + target + '"]').each(function(){
        //         var $this=$(this),
        //             o=$this.data();
        //         if(Array.isArray(o.icons)){
        //             $this.changeIcon(o.icons[expanded ? 0 : 1]);
        //         }
        //         $this.attr('data-expanded', expanded ? '0' : '1');
        //     });
            
            
            
        //     // .data('expanded', !expanded);
                
        //     // $this.data('visible', !visible);
        //     // if (show) {
        //     //     switch(show){
        //     //         case '1': 
        //     //             $container = $this.closest(containerSelector);
        //     //             // $container.find('[data-toggle-details]').data('visible', !visible);
        //     //             // var kk=$container.find('[data-toggle-details]');
        //     //             $container.children(detailsSelector)[fn]();
        //     //             break;
        //     //         case 'all':
        //     //             $(detailsSelector)[fn]();
        //     //             break;
        //     //         case 'childs':
        //     //             $this
        //     //                 .find(detailsSelector)[fn]();
        //     //         break;
        //     //         default:
        //     //             $this
        //     //                 .closest('.' + show)
        //     //                 .find(detailsSelector)[fn]();
        //     //     }
        //     // }
        // })
        // .on('click', '[data-show-child]', function() {
        //     var $this = $(this),
        //         $rowpos = $this.closest('.row-pos'),
        //         show = $this.data('show-child');
        //     if (show) {
        //         if (show === '1') {
        //             $rowpos.children('.row-child').slideToggle();
        //         } else if (show === 'all') {
        //             $('.row-child').slideToggle();
        //         } else {
        //             $rowpos
        //                 .closest('.' + show)
        //                 .find('.row-child.' + show)
        //                 .slideToggle();
        //         }
        //     }
        // })
        // .on('click', '.btn-upload', function() {
        //     var maxupload = 4,
        //         uploadSelector = $(this).data('field') ? $(this).data('field') : Bajt.html.selectorControl($(this).attr('id')),
        //         $uploadField = $(uploadSelector),
        //         count = $uploadField.find('.form-control').length;
        //     if (count < maxupload) {
        //         var $fileUpload = $('#fileupload'),
        //             fileUpload = $fileUpload.data('blueimp-fileupload') || $fileUpload.data('fileupload');
        //         fileUpload.options.uploadContainer = $uploadField;
        //         $fileUpload.click();
        //     }
        // })
        // .on('click', '.upload .btn-remove', function() {
        //     var $upload = $(this).closest('.upload');
        //     //            $position=$upload.closest('.position');
        //     $upload.slideUp('fast', function() {
        //         var data = $upload.data();
        //         if (data.hideStatus) {
        //             data.hideStatus();
        //         }
        //         $upload.remove();
        //         //            Order.attachDel($position);
        //     });
        // })
        // .on('click', '#test', function () {
        //     var $messages = $('.form-messages'),
        //         $messagesContent = $messages.find('.content');
        //     $messages.slideDown();
        // })
        // .on('click', '.btn[data-value]', function(e) {
        //     var $this = $(this),
        //         btnData = $this.data();
        //     //            value=$this.attr('data-value');
        //     if (btnData.value && !btnData.hasOwnProperty('bs.popover')) {
        //         stopTrigger(e);
        //         var tmpl = btnData['tmpl'],
        //             value = btnData.value,
        //             options = {
        //                 trigger: 'click'
        //             };
        //         if (tmpl) {
        //             var $container = $this.closest('.show-container'),
        //                 templates = $container.data('templates'),
        //                 esettings = $container.data('entity-settings');
        //             console.log(esettings);
        //             tmpl = Bajt.obj.getValue(tmpl, templates);
        //             if (tmpl) {
        //                 var $view;
        //                 if ($.isArray(value)) {
        //                     $view = $('<div class="table"></div>');
        //                     for (var i in value) {
        //                         $view.append($(tmpl).fill(value[i], 'PositionsLackers'));
        //                     }
        //                 } else {
        //                     $view = $(tmpl);
        //                     $view.fill(value, 'Lackers');
        //                 }
        //                 options['html'] = true;
        //                 value = $view.html();
        //             }
        //         }
        //         if (Bajt.str.is(value)) {
        //             options['content'] = value;
        //             $this.popover(options);
        //             $this.click();
        //         }
        //     }
        // })
        // .on('click', '#btn_close', function() {
        //     console.log('window close');
        //     console.log(window);
        //     window.close();
        // })
        // .on('keydown', '[data-navi]', keyPress.navi)
        // .on('keyNavigate', '[data-navi]', function(e, data) {
        //     var $this = $(this),
        //         $container = $this.closest(Bajt.obj.is(data) && data.container ? data.container : $this.data('navi'));
        //     if (Bajt.obj.is$($container)) {
        //         $container.naviElement($this, data);
        //     }
        //     console.log('navigate');
        // })
        // .on('click', '[data-function]', function(e) {
        //     stopTrigger(e);
        //     var $btn = $(this);
        //     $btn[$btn.data('function')]();
        // })
        // .on('change', '[data-hidden-edit]', function(e) {
        //     stopTrigger(e);
        //     $(this).trigger('hidden-edit-changed');
        // });

    // B.app.initContent($body);

    // B.app.getSettings();
    // console.log(Bajt.settings);
    // console.log('!!!!!');
    // $body.initContent();
    // $body.initModals();
});

// nieużywane
// function setValueControl(selector, prefixControl) {
//     var selector = selectorControl(selector, prefixControl),
//         $field = $(selector.field),
//         $control = $(selector.control),
//         value = $field.val(),
//         html = $field.data('default');
//     if ($field.data('txt') !== '') {
//         html = $field.data('txt');
//     } else if (value !== '') {
//         if ($field.is('select')) {
//             var $fieldOption = $field.find('option[value=' + value + ']');
//             html = $fieldOption.size() !== 0 ? $fieldOption.html() : '';
//         } else html = value;
//     }
//     $control.html(html);
// }

// function checkState(selector, chkValue, prefixControl) {
//     chkValue = isSet(chkValue) ? chkValue : '';
//     var selector = selectorControl(selector, prefixControl),
//         $field = $(selector.field),
//         $control = $(selector.control);
//     if ($field.val() !== chkValue) {
//         $control.addClass('btn-primary');
//     } else {
//         $control.removeClass('btn-primary');
//     }
// }

// function setDateTime(id, dateTime) {
//     dateTime = dateTime ? dateTime : new Date();
//     $(id + '_date_year').val(dateTime.getFullYear());
//     $(id + '_date_month').val(dateTime.getMonth());
//     $(id + '_date_day').val(dateTime.getDay());
//     $(id + '_time_hour').val(dateTime.getHours());
//     $(id + '_time_minute').val(dateTime.getMinutes());
//     $(id + '_time_second').val(dateTime.getSeconds());
// }

// function transFieldNames(obj, fields) {
//     var newobj = {};
//     for (var key in obj) {
//         var k = Bajt.getFullFieldName(key, fields), //get field name
//             o = obj[key];
//         if ($.isArray(o)) {
//             newobj[k] = [];
//             for (var i = 0; i < o.length; i++)
//                 newobj[k].push(
//                     Bajt.obj.is(o[i]) ? transFieldNames(o[i], fields.children[k]) : o[i]
//                 );
//         } else if (Bajt.obj.is(o)) newobj[k] = transFieldNames(o, fields.children[k]);
//         else newobj[k] = o;
//     }
//     return newobj;
// }

// function getValueOfVariable(variableName) {
//     var i,
//         value = '';
//     if (isSet(variableName)) {
//         var variable = variableName.split('.');
//         if (variable.length > 0) {
//             value = window[variable[0]];
//             for (i = 1; i < variable.length; i++) {
//                 value = value[variable[i]];
//             }
//         }
//     }
//     return value;
// }

// function Message(message, type, data) {
//     this.message = message;
//     this.type = type || 'i';
//     this.types = {
//         w: 'msg-warning',
//         e: 'msg-error',
//         s: 'msg-succes',
//         i: 'msq-info'
//     };
//     this.html = function (tags) {
//         var html;
//         html = '<li class="' + this.types[this.type] + '">';
//         if (Array.isArray(tags)) {
//             html += tags[0] + this.message + tags[1];
//         } else {
//             html += this.message;
//         }
//         html += '</li>';
//         return html;
//     };
// }

// function genMsg(msg) {
//     var $msg = $('<ul/>');
//     if (typeof msg !== 'undefined') {
//         if (typeof msg === 'string') $msg.append($('<li/>').html(msg));
//         else
//             for (var k in msg) {
//                 var $li = $('<li/>');
//                 var m = msg[k];
//                 if (Bajt.obj.is(m)) {
//                     if (m.hasOwnProperty('label'))
//                         $li.append($('<strong/>').html(m['label']));
//                     if (m.hasOwnProperty('type')) $li.addClass('msg-' + m['type']);
//                     if (Bajt.obj.is(m['message'])) {
//                         for (var k1 in m['message']) {
//                             if (Bajt.obj.is(m['message'][k1]))
//                                 $li.append(genMsg(m['message'][k1]));
//                             else $li.append($('<ul/>').html(m['message'][k1]));
//                         }
//                     }
//                     $li.append($('<span/>').html(m['message']));
//                 } else $li.html($('<span/>').html(m));
//                 $msg.append($li);
//             }
//     }
//     return $msg;
//     //                    $messagesContent.html(messageHtml);
//     //                    $messages.addClass('alert-success').slideDown();
// }

// end nieużywane

//function Filter(datatable_id, filterbar_id){
//    this['filters']={};
//    this['datatable']='#'+datatable_id;
//    this['filterbar']='#'+filterbar_id;
//    this['apply']=function(){
//        $(this.datatable).bootstrapTable('refresh');
//    };
//    this['read']=function(){
//        var $fields=$(this.filterbar).find('select');
//        this['filters']={};
//        $(this.filterbar).find('select').each(function(index, select){
//            var field=getFieldName(select),
//                filter=$(select).val();
//            if (filter !== ''){
//                this[field]=filter;
//            }
//        });
//    };
//}

//
// function orderDetailsFormatter(index, row) {
//     //    var
//     //        btable=this,
//     //    $details=$(this.detailsPrototype),
//     //    $positions=$details.find('.table-view'),
//     //    posPrototype=$positions.data('position-tmpl'),
//     //    lackerPrototype=$positions.data('lacker-tmpl'),
//     //    posFields= btable.entitySettings.positions.fields,
//     //    positions=fieldValue( row, 'positions', btable.entitySettings.fields);
//     //    var klk=fieldValue( row, 'id', btable.entitySettings.fields );
//     //    $positions.data('id', fieldValue( row, 'id', btable.entitySettings.fields ));
//     //    $.each(positions, function(idx, position){
//     //        var $pos=$(posPrototype),
//     //            cells=$pos.find('[data-name]');
//     //            $pos.find('.row-data').data('id', fieldValue( position, 'id', posFields ));
//     //        cells.each(function(){
//     //            var
//     //            $cell=$(this),
//     //            opt=$cell.data(),
//     //            value=fieldValue( position, opt.name, posFields ); //position[opt.name];
//     //            if (opt.collection){
//     //                $.each( value, function(idx, obj){
//     //                    $cell.append(html.showObj(obj, lackerPrototype, btable.entitySettings.lackers.dictionaries, posFields.children.positionLackers ));
//     //                });
//     //            }else if (opt.dictionary){
//     //                var row=Bajt.dic.from(btable.entitySettings.dictionaries[opt.dictionary], value);
//     //                $cell.html(row ? row['n'] : value);
//     //            }else if(opt.checked){
//     //                $cell.find('input').prop('checked', value);
//     //            }else
//     //                $cell.html(value);
//     //        });
//     //        $positions.append($pos);
//     //    });
//     //    return $details.html();
//     return this.detailsPrototype;
// }

// $.fn.initDateRange = function () {
//     $(this).find('[data-daterange]').daterangepicker({
//             showDropdowns: true,
//             linkedCalendars: false,
//             alwaysShowCalendars: true,
//             buttonClasses: 'btn',
//             autoApply: true,
//             autoUpdateInput: true,
//             startDate: moment().subtract(30, 'days'),
//             endDate: moment(),
//             locale: {
//                 format: 'YYYY-MM-DD',
//                 separator: ' - ',
//                 applyLabel: 'Ok',
//                 cancelLabel: 'Wyczyść',
//                 fromLabel: 'Od',
//                 toLabel: 'Do',
//                 customRangeLabel: 'Zakres',
//                 daysOfWeek: [
//                     'N',
//                     'Pn',
//                     'Wt',
//                     'Śr',
//                     'Cz',
//                     'Pi',
//                     'So'
//                 ],
//                 monthNames: [
//                     'Styczeń',
//                     'Luty',
//                     'Marzec',
//                     'Kwiecień',
//                     'Maj',
//                     'Czerwiec',
//                     'Lipiec',
//                     'Sierpień',
//                     'Wrzesień',
//                     'Październik',
//                     'Listopad',
//                     'Grudzień'
//                 ],
//                 firstDay: 1
//             },
//             ranges: {
//                 'Dzisiaj': [moment(), moment()],
//                 'Ostatni tydzień': [moment().subtract(6, 'days'), moment()],
//                 'Ostatnie 30 dni': [moment().subtract(29, 'days'), moment()],
//                 'Aktualny miesiąc': [moment().startOf('month'), moment().endOf('month')],
//                 'Poprzedni miesiąc': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
//                 'Ostatni kwartał': [moment().subtract(2, 'month').startOf('month'), , moment().endOf('month')],
//                 'Aktualny rok': [moment().startOf('year'), moment()]
//             }
//         })
//         .on('apply.daterangepicker', function (ev, picker) {
//             $(this).val(picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD'));
//         })
//         .on('cancel.daterangepicker', function (ev, picker) {
//             $(this).val('');
//         });
// };

/*
$('body').on('click', '.modal button.btn-confirm', function (e) {
    e.preventDefault();
    var $modal=findParentByClass(this, 'modal');
    if ($modal.length){
        var data=JSON.stringify($modal.find('.exp-table').bootstrapTable('getData'));
        var mm=$modal.data('method');
        var url=$modal.data('action');
        var datatable=$modal.data('datatable');
        $.ajax({
            type: $modal.data('method'),
            url: $modal.data('action'),
            data: data//JSON.stringify($modal.find('.exp-table').bootstrapTable('getData'))
        })
        .done(function (data) {
            if (typeof data.message !== 'undefined') {
               var $footer=$modal.find('.modal-footer');
               $footer.find('.btn-save').fadeOut();
               $footer.find('.btn-cancel').fadeOut();
               var ii=$modal.find('.form-messages');
               $modal.find('.form-messages').html(data.message).addClass('alert-success').slideDown();
               $footer.find('.btn-ok').fadeIn();
            }
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
            if (typeof jqXHR.responseJSON !== 'undefined') {
                if (jqXHR.responseJSON.hasOwnProperty('form_body')) {
                    $modal.find('form_body').html(jqXHR.responseJSON.form_body);
                }
 
                $modal.find('.form-errors').html(jqXHR.responseJSON.message).slideDown();
            } else {
                alert(errorThrown);
            }
 
        });
    }
});
*/

// $.fn.initAjaxCarlack = function () {
//     //        $(this).on('submit', '.ajaxCarlack', function (e) {
//     //            stopTrigger(e);
//     //            var
//     //                fdata,
//     //                tableName,
//     //                $modal=$(this).closest('.modal'),
//     //                $panel=$(this).closest('.panel'),
//     //                $form=$(this),
//     //                formName=$form.attr('name'),
//     //    //            $modal.find('.form-errors').slideUp().empty();
//     //    //            $modal.find('.form-messages').slideUp().empty();
//     //    //            $modal.find('.has-error').removeClass('has-error');
//     //    //            $modal.find('.has-warning').removeClass('has-warning');
//     //                $errors=$form.find('.form-errors'),
//     //                $errorsContent= $errors.find('.content'),
//     //                $messages=$form.find('.form-messages'),
//     //                $messagesContent= $messages.find('.content'),
//     //                _showMessage=function(messages, classes){
//     //                    var $container=$('<div class="alert"></div>');
//     //                    if (classes) $container.addClass(classes);
//     //                        $messages.showMessages(messages, $container);
//     //                },
//     //                _showErrors=function(errors){
//     //                     $errors.showMessages(errors, $('<div class="alert alert-danger"></div>'));
//     //                };
//     //                if (Bajt.obj.is$($modal))
//     //                    tableName=$modal.data('table') || $modal.attr('id').replace('_modal', '');
//     //                else if ( Bajt.obj.is$($panel) )
//     //                    tableName=$panel.data('table')|| $panel.attr('id').replace('_panel', '');
//     //
//     //            _showMessages({ message : 'Trwa zapis do bazy danych test', type: 'i'}, 'alert-info');
//     //            var
//     //                $submit=$form.find(':submit'),
//     //                $id=$form.find('#'+formName+'_id');
//     //            $submit.attr('disabled', true);
//     //            console.log('!!!!ajax submit');
//     //            $.ajax({
//     //                type: $form.attr('method'),
//     //                url: $form.attr('action'),
//     //                data: $form.serialize()
//     //            })
//     //            .done(function (data) {
//     //                var isNew=!isSet($id.val()),
//     //                    showMessage= (typeof data.show  !== 'undefined' && data.show === '1') ||Bajt.obj.is$($panel);
//     //                if (isNew){
//     //                    $id.val(data.id);
//     ////                    $form.attr('method', 'PUT').attr('action', $form.attr('action').replace('create', data.id+'/update'));
//     //                    $form.attr('method', 'PUT').attr('action', Bajt.url.modify($form.attr('action'), '/create', '/'+data.id+'/update'));
//     ////                    var currentURL = window.location.href;
//     ////                    var newURL = currentURL.replace('/new', '/'+data.id+'/update');
//     //                    var newURL = Bajt.url.modify(window.location.href, '/new', '/'+data.id+'/edit');
//     //                    window.history.replaceState('', '', newURL);
//     //                    $submit.attr('title','Aktualizuj').html('Aktualizuj').val('Aktualizuj');
//     //                }
//     //
//     //                if (Bajt.obj.is$($modal) && !(data.show) ){
//     //                    if ( tableName && tableName in dataTables )
//     //                        dataTables[tableName].ajax.reload();
//     //                    $modal.modal('hide');
//     //                }else if (data.show === undefined || data.show){
//     //                    _showMessages(data.messages, 'alert-success');
//     //                }
//     //                if (Bajt.obj.is$($modal))
//     //                    $modal.trigger('submited', data );
//     //                else
//     //                    $form.trigger('submited', data );
//     //                $submit.attr('disabled', false);
//     //                console.log('ajax submited');
//     //                console.log(data);
//     //            })
//     //            .fail(function (jqXHR, textStatus, errorThrown) {
//     //                _showMessages();
//     //                if (typeof jqXHR.responseJSON !== 'undefined') {
//     //                    if (jqXHR.responseJSON.hasOwnProperty('form_body')){
//     //                         $form.find('.form_body').html(jqXHR.responseJSON.form_body);
//     //                        console.log('form_errors');
//     //                         $form.initFormWidgets();
//     //                }
//     //                    _showErrors(jqXHR.responseJSON.errors);
//     //                }else if (jqXHR.responseText)
//     //                    _showErrors(jqXHR.responseText);
//     //                else
//     //                    alert(errorThrown);
//     //                $submit.attr('disabled', false);
//     //
//     ////                        if (typeof jqXHR.responseJSON !== 'undefined') {
//     ////                    if (jqXHR.responseJSON.hasOwnProperty('form_body')) {
//     ////                         $form.find('.form_body').html(jqXHR.responseJSON.form_body);
//     ////                         $form.initFormWidgets();
//     ////                }
//     ////                    $errorsContent.append($(msgPrototype).addClass('alert-danger').append((new MSG(jqXHR.responseJSON.errors)).$html()));
//     ////
//     //////                    $errorsContent.append(genMsg(jqXHR.responseJSON.errors));
//     ////                    $errors.slideDown();
//     ////                } else {
//     ////                    alert(errorThrown);
//     ////                }
//     //            });
//     //        });
// };

//    $.fn.initAjaxCarlack=function(){
//        $(this).on('submit', '.ajaxCarlack', function (e) {
//            e.preventDefault();
//            var
//                msgPrototype='<div class='alert alert-dismissible fade in" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>',
//                $modal=$(this).parents('.modal'),
//                $panel=$(this).parents('.panel'),
//                $form=$(this),
//                fdata=$form.serialize(),
//                $submit=$form.find(':submit').attr('disabled', true),
//    //            $modal.find('.form-errors').slideUp().empty();
//    //            $modal.find('.form-messages').slideUp().empty();
//    //            $modal.find('.has-error').removeClass('has-error');
//    //            $modal.find('.has-warning').removeClass('has-warning');
//                $errors=$form.find('.form-errors'),
//                $errorsContent= $errors.find('.content'),
//                $messages=$form.find('.form-messages'),
//                $messagesContent= $messages.find('.content'),
//                tableName=null;
//                if (isSet($modal))
//                    tableName=$modal.data('table') || $modal.attr('id').replace('_modal', '');
//                else if ( isSet($panel) && $panel.length===1 )
//                    tableName=$panel.data('table')|| $panel.attr('id').replace('_panel', '');
//                $form.find('.has-error').removeClass('has-error');
//                $form.find('.has-warning').removeClass('has-warning');
//                var formName=$form.attr('name');
//                var $id=$form.find('#'+formName+'_id');
//                $errors.slideUp(function(){
//                    $errorsContent.empty();
//                });
//                $messages.slideUp(function(){
//                    $messagesContent.empty();
//                });
//            $.ajax({
//                type: $form.attr('method'),
//                url: $form.attr('action'),
//                data: fdata
//            })
//            .done(function (data) {
//                var isNew=!isSet($id.val()),
//                    showMessage= (typeof data.show  !== 'undefined' && data.show === '1') ||isSet($panel);
//                if (isNew){
//                    $id.val(data.id);
////                    $form.attr('method', 'PUT').attr('action', $form.attr('action').replace('create', data.id+'/update'));
//                    $form.attr('method', 'PUT').attr('action', Bajt.url.modify($form.attr('action'), '/create', '/'+data.id+'/update'));
//
////                    var currentURL = window.location.href;
////                    var newURL = currentURL.replace('/new', '/'+data.id+'/update');
//                    var newURL = Bajt.url.modify(window.location.href, '/new', '/'+data.id+'/edit');
//                    window.history.replaceState('', '', newURL);
//                    $submit.attr('title','Aktualizuj').html('Aktualizuj').val('Aktualizuj');
//                }
//                if (isSet($modal) && (!data.hasOwnProperty('show') || !data.show)){
//                    if ( tableName && tableName in dataTables )
//                        dataTables[tableName].ajax.reload();
//                    $modal.modal('hide');
//                }else{
//                    $messagesContent.append($(msgPrototype).addClass('alert-success').append((new MSG(data.messages)).$html()));
//                    $messages.slideDown();
//                }
//                $submit.attr('disabled', false);
//                $form.trigger('submited', data );
//            })
//            .fail(function (jqXHR, textStatus, errorThrown) {
//                if (typeof jqXHR.responseJSON !== 'undefined') {
//                    if (jqXHR.responseJSON.hasOwnProperty('form_body')) {
//                         $form.find('.form_body').html(jqXHR.responseJSON.form_body);
//                         $form.initFormWidgets();
//                }
//                    $errorsContent.append($(msgPrototype).addClass('alert-danger').append((new MSG(jqXHR.responseJSON.errors)).$html()));
//
////                    $errorsContent.append(genMsg(jqXHR.responseJSON.errors));
//                    $errors.slideDown();
//                } else {
//                    alert(errorThrown);
//                }
//                $submit.attr('disabled', false);
//            });
//        });
//    };
