(function($, B) {
    'use strict';



    $.widget(
        'bajt.pricesItem',
        $.extend(true, {}, B.formCollectionItem, {
            options: {
                ecn: ECN.PRICE,               
                uniqueField: 'priceListItem',
                formFields: {
                    priceListItem: {
                        options: {
                            disp: {
                                type: 'dic.n'
                            },
                            dictionary: true
                        }
                    },
                    oryginalId: {},
                    value: {
                        options: {
                            precision: 2,
                            autocorrect: true,
                            type: 'float',
                            check_key: 1,
                            navi: 1,
                            selectOnFocus: true,
                            widget: {
                                type: 'nettoinput'
                            }
                        }
                    }
                }
            }
        })
    );   

    $.widget(
        'bajt.prices',
        $.extend(true, {}, B.formCollectionImportDT, {
            options: {
                ecn: ECN.PRICE,
                focusField: 'value',               
                dtExport:{
                    fields: ['id', 'price']
                },
                unique: {
                    on: true,
                    showFilter: true,
                    filter: true
                },
                itemOptions:{
                    widget: 'pricesItem'
                },
                locale: {
                    validate: {
                        collection: 'Pozycje cennika'
                    }
                }
            },

            _itemImportData: function(inData) {                
                var out={
                    value : inData.price
                };
                out.priceListItem = inData.id;
                return out;
            }
        })
    );   

    $.widget(
        'bajt.priceListForm',
        $.extend(true, {}, B.basicForm, {
            options: {            
                expBtns: true,
                formFields: {
                    client : {},
                    clientGroup: {},
                    title: {},
                    start: {
                        options: {
                            type: 'date',
                            format: 'YYYY-MM-DD HH:mm',
                            disp: false,
                            widget: {
                                type: 'datepicker',
                                options: {
                                    autoUpdateInput: false,
                                    locale: {
                                        format: 'YYYY-MM-DD HH:mm',
                                    }
                                }
                            }
                        }
                    },
                    end: {
                        options: {
                            type: 'date',
                            format: 'YYYY-MM-DD HH:mm',
                            disp: false,
                            widget: {
                                type: 'datepicker',
                                options: {
                                    autoUpdateInput: false,
                                    locale: {
                                        format: 'YYYY-MM-DD HH:mm',
                                    }
                                }
                            }
                        }              
                        // options: {
                        //     type: 'date',
                        //     widget: {
                        //         type: 'datepicker',
                        //         options: {
                        //             locale: {
                        //                 format: 'YYYY-MM-DD'
                        //             },
                        //             startDate: true,
                        //             autoUpdateInput: false,
                        //         }
                        //     }
                        // }
                    },
                    description:{},
                    prices:{
                        fieldWidget: 'prices'                   
                    }
                }
            },
            _bind: function() {
                B.basicForm._bind.call(this);
                return this;
            }
        })
    );

    $.fn.initFormPriceList = function() {
        var $form = $(this).find('form[data-form=pricelist]');
        if (B.obj.is$($form)) {
            $form.priceListForm();
        }
    };
})(jQuery, Bajt);

