var prefixId='#app_';


var contentInits=[
    'initFormWidgets',
    'initFieldBtn',
    'initFilterBar',
    'initToolBar',
    'initDataTables',
    'initAjaxForm',
    'initTestForms',
    'initLoginForm',
    'initMultiEditForm',
    'initShowForm',
    'initClientService',
    'initFormOrder',
    'initFormCalculation',
    'initFormGuestOrder',
    'initFormProduction',
    'initFormDelivery',
    // 'initFormPriceListItem',
    'initFormProductionProcess',
    'initFormPriceList',
    'initFormPriceListItemsGenerate',
    'initFormPriceListItemsCollection',
    'initOffer',
    'initFormOfferItem',
    'initFormOfferItemsGenerate',
    'calcOverflow',
    'initPreview',
    'initElementToggle'
];