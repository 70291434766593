// function validateEntityName(name) {
//     return (name === 'positionLackers' ? 'lackers' : (name.slice(-1) === 's' ? name : name + 's'));
// }
var ECN = {
    USER : 'User',
    CLIENT : 'Client',
    CLIENTGROUP : 'ClientGroup',
    ORDER : 'ProductsOrder',
    POSITION : 'Position',
    PRODUCTION: 'Production',
    PRODUCTIONPROCESS: 'ProductionProcess',
    PRODUCTIONSTAGE: 'ProductionStage',
    PRODUCTIONPROCESSSTAGE: 'ProductionProcessStage',
    PRODUCT: 'Product',
    PRICE: 'Price',
    TRIM: 'Trim',
    PRICELISTITEM: 'PriceListItem',
    PRICELIST: 'PriceList',
    CALCULATION: 'Calculation',
    COLOR : 'Color',
    MODEL : 'Model',
    SIZE : 'Size'
};

(function($, B) {
    'use strict';
    $.extend(true, B, {
        settings: {
            entities: {}
        },
        entity: {
            // getDefaultValues:function(entity, entityClassName, options){
            //     var values={};
            //     if(options.child){
            //         values[B.getFullFieldName(options.child)] = B.getFieldsValues(
            //             B.getFieldValue(entity, options.child, entityClassName),
            //             B.entity.getChildClass(options.child, entityClassName),
            //             options.fields
            //         );
            //     }else{
            //         B.getFieldsValues(entity, entityClassName, options.fields);
            //     }
            //     return values;
            // },
            // getFillOptions: function(entityClassName){
            //     var options={};
            //     switch(entityClassName){
            //         case 'Orders':
            //             options['positions']
            //         break;
            //     }
            //     return options;
            // },
            getChildFillOptions: function(entity, entityClassName, childEntityClassName){
                var def, opt = {};
                switch(childEntityClassName){
                    case ECN.POSITION:
                        if(entityClassName == ECN.ORDER ){
                            def = B.getFieldsValues(entity, ['model', 'size', 'color', 'trims' ], entityClassName );
                            if(B.obj.isNotEmpty(def)){
                                opt['checkDiff']={};
                                for (var i in def ){
                                    opt.checkDiff[i] = { 
                                        value : def[i]
                                    };
                                }
                            }
    
                        }
                    break;
                }
                return opt;
            },

            decode: function(value, fieldName, entityClassName, def){
                var val = B.json.is(value) ? JSON.parse(value) : value,
                    dic=B.dic.get(fieldName, entityClassName),
                    _decode = {
                        dic:function(v){
                            return B.dic.find(dic, v);
                        },
                        trims: function(v){
                            var a = Array.isArray(v) ? v : B.astring.toArray(v);
                            return  _decode.dic(a);
                        },
                        def:function(v){
                            return (!B.obj.is(v) && Array.isArray(dic)) ? _decode.dic(v) : v;
                        }
                    };
                val = _decode[typeof _decode[fieldName] == 'function' ? fieldName : 'def'](val);
                return (def !== undefined && ( val === undefined || val === null)) ? def: val;
            },
            isEqual:function(val1, val2, fieldName){
                var equal=false,
                    _isEqual={
                        array:function(v1, v2){
                            return B.array.haveSameValues(v1, v2);
                        },
                        astring:function(v1, v2){
                            return B.astring.isEqual(v1, v2);
                        },
                        options:function(v1, v2){
                            return B.array.haveSameValues(v1, v2);
                        },
                        obj:function(v1, v2){
                            return B.obj.haveSameValues(v1, v2);
                        }
                    };
                switch(fieldName){
                    case 'trims':
                        equal = _isEqual.astring(val1, val2);
                    break;
                    case 'lackers':
                    case 'positionLackers':
                        equal = true;
                        if (Array.isArray(val1) && $.isArray(val2) && val1.length === val2.length) {
                            for (var i = 0; i < val1.length && equal; i++) {
                                equal =
                                    equal &&
                                    val1[i].sq === val2[i].sq &&
                                    val1[i].opt === val2[i].opt &&
                                    val1[i].com === val2[i].com &&
                                    val1[i].color.id === val2[i].color.id;
                            }
                        } else {
                            equal = false;
                        }
                    break;
                    default:
                        if(Array.isArray(val1) && Array.isArray(val2)){
                            equal = _isEqual.array(val1, val2);
                        }else if(B.astring.is(val1) || B.astring.is(val2)){
                            equal = _isEqual.astring(val1, val2);
                        }else{
                            equal = _isEqual.obj(val1, val2);
                        }
                }
                return equal;
            },
            fill: function(entity, entityClassName, options) {
                if(entity.filled){
                    return entity;
                }
                entity = B.obj.compose(entity);
                var ne = {
                        filled: true
                    },
                    o = $.extend({
                            def: ''
                        },
                        options || {}
                    );
                for (var key in entity) {
                    var k,
                        fullName = B.getFullFieldName(key, entityClassName),
                        val = B.entity.decode(entity[key], fullName, entityClassName, o.def),
                        checkDiff = B.obj.getValue( 'checkDiff.'+fullName, o),
                        diff = B.obj.is(checkDiff) ? !B.entity.isEqual(checkDiff.value, val, fullName) : null;   
                    if(B.obj.is(val)){
                        var child_ecn = B.entity.getChildClass(fullName, entityClassName);
                        if(child_ecn){
                            var child_options = $.extend(true, {}, o, B.entity.getChildFillOptions(entity, entityClassName, child_ecn));
                            if (Array.isArray(val)) {
                                var childs=val;
                                val=[];
                                for (var i = 0, ien = childs.length; i < ien; i++) {
                                    val.push( B.entity.fill(childs[i], child_ecn, child_options) );
                                }
                            } else {
                                val = B.entity.fill(val, child_ecn, child_options);
                            }
                        }
                    }
                    ne[fullName] = checkDiff ? $.extend({ Value: val, diff: diff }, checkDiff.opt || {}) : val;
                }
                return ne;
            },
            getChildClass: function(field, fields) {
                if (typeof fields === 'string') {
                    fields = B.entity.getFields(fields);
                }
                if (B.obj.is(fields) && fields.hasOwnProperty('childs')) {
                    return fields.childs[typeof field === 'string' ? field : field[0]];
                }
                return null;
            },
            getChildName: function(field, fields) {
                var childName = this.entity.getChildClass(field, fields),
                    es = B.settings.entities;
                if (es.hasOwnProperty(childName)) {
                    return es[childName].en;
                }
                return null;
            },
            getFields: function(entityClassName) {
                return B.entity.getSettingsValue(entityClassName, 'fields');
                // return B.obj.getValue( [entityClassName, 'fields'],  B.settings.entities);
            },
            getChildFields: function(field, fields) {
                return B.entity.getFields(B.entity.getChildClass(field, fields));
                // return B.obj.getValue( [B.entity.getChildClass(field, fields), 'fields'],  B.settings.entities);
            },
            getDicFields: function(entityClassName) {
                return B.entity.getSettingsValue(entityClassName, 'dicFields');
                // return B.obj.getValue( [entityClassName, 'dicFields'],  B.settings.entities);
            },
            getChildDicFields: function(field, fields) {
                return B.entity.getDicFields(B.entity.getChildClass(field, fields));
                // return B.obj.getValue( [ B.entity.getChildClass(field, fields), 'dicFields'],  B.settings.entities);
            },
            getName: function(entityClassName) {
                return B.entity.getSettingsValue(entityClassName, 'en');
                // return B.obj.getValue( [entityClassName, 'en'],  B.settings.entities);
            },
            genName: function(entityClassName) {
                return entityClassName.toLowerCase();
            },
            genFieldName: function(entityClassName) {
                return B.str.firstLower(entityClassName);
            },
            getFieldName: function(entityClassName) {
                return B.entity.getSettingsValue(entityClassName, 'fieldName');
                // return B.obj.getValue( [entityClassName, 'fieldName'],  B.settings.entities);
            },
            getCollectionFieldName: function(entityClassName) {
                return B.entity.getSettingsValue(entityClassName, 'collectionFieldName');
                // return B.obj.getValue( [entityClassName, 'collectionFieldName'],  B.settings.entities);
            },
            genCollectionFieldName: function(entityClassName) {
                return B.entity.genFieldName(entityClassName) + 's';
            },
            getDTSelector: function(entityClassName){
                return '#' + B.entity.getSettingsValue(entityClassName, 'DTId');
                // return '#' + B.obj.getValue( [entityClassName, 'DTId'],  B.settings.entities);               
            },
            genDTSelector: function(entityClassName){
                return '#' + B.entity.genName(entityClassName) + '_table' ;               
            },
            export: function(entity, entityClassName, fields, fnExport) {
                var ecn, dataIn, dataOut={};
                if(B.obj.is(entityClassName)){
                    ecn = entityClassName.ecn;
                    fields = entityClassName.fields;
                    fnExport = entityClassName.fnExport;
                }else{
                    ecn = entityClassName;
                }
                if (!B.obj.is(fields)) {
                    fields = ['id'];
                }
                dataIn = B.entity.fill(entity, ecn);
                for (var i in fields) {
                    var val = B.obj.getValue(fields[i], dataIn);
                    val = B.json.is(val) ? JSON.parse(val) : val;
                    if (B.obj.is(val) && val.hasOwnProperty('v')) {
                        val = parseInt(val.v, 10);
                    }
                    if (isNaN(Number(i))) {
                        dataOut[i] = val;
                    } else {
                        dataOut[fields[i]] = val;
                    }
                }
                dataOut = B.convertDataGroup(dataOut);
                return typeof fnExport === 'function' ? fnExport(dataOut, entity) : dataOut;
            },
            getSettings: function(entityClassName){
                var es = B.settings.entities[entityClassName];
                if(!B.obj.is(es)){
                    console.log('Not found entitySettings: ' + entityClassName);
                }
                return es;
            }, 
            getSettingsValue: function(entityClassName, name){
                return B.obj.getValue(name, B.entity.getSettings(entityClassName));
            }, 
            getIdPrototype: function(entityClassName){
                return B.entity.getSettingsValue(entityClassName, 'eid');
            },
            getUrlPrototype:function(entityClassName, name){
                return B.entity.getSettingsValue(entityClassName, ['urls', name]);
            },    
            getUrl:function(entityClassName, id, action){
                var o  = undefined === action ? 'show' : action; 
                if(!B.obj.is(o)){
                    o = {
                        name: o
                    };
                }
                var url = B.entity.getUrlPrototype(entityClassName, o.name);
                if(url){
                    url = url.replace(B.entity.getIdPrototype(entityClassName), id);
                    if(o.parameters){
                        url = B.url.updateParameters(url, o.parameters);
                    }
                }
                return url;
            }
        },

        convertDataGroup: function(data) {
            var key,
                keys,
                _set = function(obj) {
                    var k0 = keys.shift();
                    if (keys.length > 0) {
                        if (!B.obj.is(obj[k0])) {
                            obj[k0] = {};
                        }
                        _set(obj[k0]);
                    } else {
                        obj[k0] = data[key];
                    }
                };
            if (B.obj.is(data)) {
                for (key in data) {
                    keys = key.split('.');
                    if (keys.length > 1) {
                        _set(data);
                        delete data[key];
                    }
                }
            }
            return data;
        },
        genNumber: function(generator, nr, options) {
            var gen = [],
                entity = B.obj.getValue('entity', options, {}),
                fields = B.obj.getValue('fields', options, {});
            nr = nr ? String(nr) : '';
            if (B.obj.is(generator)) {
                for (var k in generator) {
                    var g = generator[k];
                    if (B.obj.is(g)) {
                        var type = B.obj.getValue('type', g, '').toLowerCase(),
                            code = B.obj.getValue('code', g, '');
                        switch (type) {
                            case 'field':
                                var v = B.getFieldValue(entity, code, fields);
                                if (v !== undefined) {
                                    gen.push(String(v));
                                }
                                break;
                            case 'date':
                                gen.push(moment.format(code));
                                break;
                            default:
                                switch (code) {
                                    case 'nr':
                                        gen.push(nr);
                                        break;
                                    default:
                                        gen.push(code);
                                }
                        }
                    } else {
                        gen.push(g);
                    }
                }
            }
            return gen.length > 0 ? gen.join('') : nr;
        },
        getDicFieldNames: function(field, fields) {
            var names = (function() {
                    if ($.isArray(field)) {
                        return field;
                    }
                    return field.indexOf('.') > 0 ? field.split('.') : field.split('_');
                })(),
                fns = [],
                sns = [];
            if (typeof fields === 'string') {
                fields = B.entity.getDicFields(fields);
            }
            for (var i = 0; i < names.length; i++) {
                var fn = names[i],
                    sn = names[i];
                if (B.obj.is(fields)) {
                    for (var n in fields) {
                        if (fn === n || fn === fields[n]) {
                            fn = n;
                            sn = fields[n];
                            break;
                        }
                    }
                    if (i + 1 < names.length) {
                        fields = B.entity.getChildDicFields(fn, fields);
                    }
                }
                fns.push(fn);
                sns.push(sn);
            }
            return [fns.join('.'), sns.join('.')];
        },
        getFieldNames: function(field, fields) {
            var names = (function() {
                    if (Array.isArray(field)) {
                        return field;
                    }
                    return field.indexOf('.') > 0 ? field.split('.') : field.split('_');
                })(),
                fns = [],
                sns = [];
            if (typeof fields === 'string') {
                fields = B.entity.getFields(fields);
            }

            for (var i = 0; i < names.length; i++) {
                var fn = names[i],
                    sn = names[i];
                if (B.obj.is(fields)) {
                    for (var n in fields) {
                        if (fn === n || fn === fields[n]) {
                            fn = n;
                            sn = fields[n];
                            break;
                        }
                    }
                    if (i + 1 < names.length) {
                        fields = B.entity.getChildFields(fn, fields);
                    }
                }
                fns.push(fn);
                sns.push(sn);
            }
            return [fns.join('.'), sns.join('.')];
        },
        fieldsNames:function(fields){
            return (typeof fields === 'string') ? B.entity.getFields(fields) : fields;
        },
        getFieldValue: function(obj, name, fields, key) {
            if (!B.obj.is(obj)) {
                return undefined;
            }
            fields=B.fieldsNames(fields);
            var names = name.split('.'), //nazwa zmiennej w obiekcie zagłębienie rozdzielone . (obj.obj1.obj2.val)
                value = obj,
                i = 0,
                n;
            while (undefined !== value && names[i]) {
                n = this.getFieldNames(names[i], fields); //pobranie nazwy lub skrótu
                if (typeof value === 'undefined') {
                    console.log('value undefined');
                    console.log(n);
                }
                value = typeof n === 'string' ? value[n] : value[n[0]] || value[n[1]];
                if(B.obj.is(value) && value.Value){
                    value = value.Value;
                }
                fields = this.entity.getChildFields(n, fields);
                i++;
            }
            return B.obj.is(value) && key ? value[key] : value;
        },        
        setFieldValue: function(obj, name, fields, value) {
            if (typeof fields === 'string') {
                fields = this.entity.getFields(fields);
            }
            var n = this.getFieldNames(name, fields),
                key;
            if (typeof n === 'string') {
                key = n;
            } else if (n[0] in obj) {
                key = n[0];
            } else {
                key = n[1];
            }
            obj[key] = value;
            return obj;
        },
        getFieldsValues: function(obj, names, fields, key){
            if (!B.obj.is(obj)) {
                return undefined;
            }
            var values={};
            fields=B.fieldsNames(fields);
            for(var i in names){
                values[B.getFullFieldName(names[i], fields)]=B.getFieldValue(obj, names[i], fields, key);
            }
            return values;
        },
        getFromDictionary: function(dicName, entityClassName, value, options) {
            return B.dic.from(B.dic.get(dicName, entityClassName), value, $.extend( { ecn: entityClassName}, options));
        },
        findInDictionary: function(dicName, entityClassName, value) {
            return B.dic.find(B.dic.get(dicName, entityClassName), value);
        },
        getFullFieldName: function(field, fields) {
            if (typeof fields === 'string') {
                fields = this.entity.getFields(fields);
            }
            var names = this.getFieldNames(field, fields);
            return B.obj.is(names) ? names[0] : names || '';
        },
        getEntitySettings: function(entityClassName) {
            return B.entity.getSettings(entityClassName);
        },
        html: {
            showObj: function(obj, tmpl, entityClassName) {
                var that = this,
                    $show = $(tmpl),
                    fields = this.entity.getFields(entityClassName),
                    $fields = $show.find('[data-name]');
                $fields.each(function() {
                    var $f = $(this),
                        opt = $f.data(),
                        value = that.getFieldValue(obj, opt.name, fields);
                    if (opt.dictionary) {
                        var row = B.dic.from(opt.dictionary, value);
                        value = row ? row['n'] : value;
                    }
                    if (opt.tip) {
                        if (value) {
                            $f.data('content', value);
                            $f.popover({ container: 'body' });
                            $f.show();
                        }
                    } else {
                        // var type = $f.getFieldType();
                        //            var gg= $.inArray(type, ['select','input','textarea'] ) >=0;
                        //            if ($f.hasClass('form-control'))
                        if ($f.is(':input')) {
                            $f.val(value);
                        } else {
                            $f.html(value);
                        }
                        $f.attr('title', value);
                    }
                    if (opt.signal) {
                        if (value) {
                            $f.show();
                        } else {
                            $f.hide();
                        }
                    }
                });
                return $show;
            }
        }
    });

    $.fn.fill = function(data, entityClassName, options) {
        var $this = $(this),
            o = $.extend(
                true,
                {
                    propertyName: 'name',
                    empty: '',
                    full: false,
                    diffClass: 'diff',
                    templates: {
                        // diffSignal: B.html.get('icon', { icon: 'star', addClass: 'diff-signal' }),
                        diffWrap: '<span class="diff-show"></span>'
                    }
                },
                options || {},
                $this.data() || {}
            ),
            // _template = function(field) {               
            //     field.tmpl = field.options.tmpl || o.templates[field.options.tmplName || field.fullname];
            //     // var p;
            //     // if (B.obj.is(field)) {
            //         // if (o.templates) {
            //         //     p = o.templates[field.options.tmplName || field.fullname];
            //         // }
            //         // if (!p) {
            //         //     p = field.options.tmpl;
            //         // }
            //         // field.tmpl = p;
            //     // }
            //     //  else {
            //     //     if (o.templates) {
            //     //         p = o.templates[field === 'positionLackers' ? 'lackers' : field];
            //     //     }
            //     //     if (!p) {
            //     //         p = $element.data('tmpl');
            //     //     }
            //     // }
            //     // return p;
            // },
            _checkDiff= function(field){
                if ( B.obj.is(field.value) && 'Value' in field.value) {
                    field.defVal = field.value.defVal;
                    field.diff = field.value.diff;
                    field.value = (field.diff === false && field.value.sameVal) 
                        ? field.value.sameVal
                        : field.value.Value;
                } 
            },
            _convert = function(field, value) {
                var val = value !== undefined ? value : field.value,
                    fo = field.options || {},
                    disp = B.str.is(fo.disp) ? fo.disp : 'name';
                if (val === undefined || val === null) {
                    val = fo.empty || o.empty;
                }else if(fo.convert){
                    val = B.convert(val, fo.convert);
                }else if( fo.dicName || !B.obj.is(val) ) {
                    val = B.getFromDictionary(
                        fo.dicName || field.fullname,
                        fo.ecn || field.ecn,
                        val
                    ) || val;
                }               
                if(disp && B.obj.is(val)){
                    var val1 = B.getFieldValue(val, disp, field.ecn);
                    if(val1 !== undefined){
                        val = val1;
                    }  
                }
                    
                return val;
            },
            _show = function(field, value) {
                var val = value !== undefined ? value : field.value,
                    signal = B.obj.getValue('options.signal', field),
                    _html = function(){
                        var html;
                        if (B.obj.is(val)) {
                            html = field.tmpl 
                                ? $(field.tmpl).fill(val, field.ecn, o) 
                                : JSON.stringify(val);
                        }else if( field.tmpl ) {
                            html = field.tmpl.indexOf('data-container') > 0 //jeśli prototyp przeznaczony na tabelę ale wartość nie jest tabelą
                                ? val
                                : field.tmpl.replace('__v__', val);
                        }else{
                            html = val;
                        }       
                        return html;
                    };
                if(field.options.disp !== 'fill' ){
                    val = _convert(field, val);
                }
                if (signal) {
                    field.$element.html(signal);
                }else{
                    var wrapper = field.diff ? B.obj.getValue('options.diffWrap', field, o.templates.diffWrap ) : null;
                    field.$element.append(wrapper ? $(wrapper).html(_html()) : _html());
                }
            },
            _showDiffSignal = function(field){
                if(field.diff){
                    var signal =  B.obj.getValue('options.diffSignal', field, o.templates.diffSignal );
                    if(signal){
                        field.$element.prepend(signal);
                    }
                }
            },
            _traversData = function(field) {
                var names = field.options.name.split('.'); //nazwa zmiennej w obiekcie zagłębienie rozdzielone . (obj.obj1.obj2.val)
                for (var i = 0; i < names.length; i++) {
                    var n = B.getFieldNames(names[i], field.ecn);
                    field.fullname = n[0];
                    if(field.ecn && (!B.obj.is(field.value) || (field.value[n[0]] === undefined && field.value[n[1]] === undefined ))){
                        field.value = B.dic.getRow( field.dicName || field.ecn, field.ecn, field.value);
                    }
                    field.value = B.getValue(field.value, n);                   
                    field.ecn = B.entity.getChildClass(n[0], field.ecn) || field.ecn;
                }
                return field;
            },
            _getValue=function(key, value, def){
                var val = key ? B.obj.getValue(key, value, def || '') : value;
                return B.obj.is(val) && val.hasOwnProperty('Value') ? val.Value : val;
            },
            _valueToStr=function(value){
                return B.obj.is(value) ? JSON.stringify(value) : value;
            },
            _setAttr=function($element, name, value){
                $element.attr(name, _valueToStr(value));
            },
            _setAttrValues = function(field){
                var attr = B.obj.getValue('options.attr', field, []);
                for (var i in attr) {
                    _setAttr(field.$element, attr[i].name, _getValue(attr[i].key, field.value, attr[i].def ));
                }
            },
            _setDataValues = function(field) {
                var nv = B.obj.getValue('options.values', field, [] );
                for (var i in nv) {
                    var val=_getValue(nv[i].key, field.value);
                    _setAttr(field.$element, 'data-' + nv[i].name, val);
                    field.$element.data(nv[i].name, val);
                }
            },
            _disp=function(field){
                var fo = field.options,
                    $f = field.$element;
                if (o.excludeFields && o.excludeFields.indexOf(fo.name) >= 0) {
                    return true;
                }
                _traversData(field);
                _setAttrValues(field);
                _setDataValues(field);
                if (fo.edit) {
                    var editValue = B.obj.is(field.value) && field.value.hasOwnProperty('Value') ? field.value.Value : field.value;
                    if (B.obj.is(editValue)) {
                        editValue = B.obj.getValue(fo.edit, editValue);
                    }
                    if(isSet(editValue)){
                        $f.writeField(editValue);
                    }
                } else {
                    _checkDiff(field);
                    if (fo.disp) {
                        field.tmpl = fo.tmpl || o.templates[fo.tmplName || field.fullname];                   
                        if (!fo.notClear){
                            $f.empty();
                        }
                        if(fo.prefixContent){
                            $f.append(fo.prefixContent);
                        }
                        if (Array.isArray(field.value)) {
                            if (field.tmpl) {
                                var $c = $(field.tmpl);
                                if ($c.data('container') !== undefined) {
                                    //sprawdzenie czy jest container
                                    $f.append($c);
                                    $f = $c;
                                    field.tmpl = o.templates[field.fullname + '_entry'] || $c.data('tmpl');
                                }
                            }
                            for (var i = 0, ien = field.value.length; i < ien; i++) {
                                _show(field, field.value[i]);
                            }
                        } else {
                            _show(field);
                        }
                        _showDiffSignal(field);
                    }
                }
                $f[ field.diff ? 'addClass' : 'removeClass' ](B.obj.getValue('diffClass', fo, o.diffClass));
            };
        if(o[o.propertyName]){
            _disp({ 
                $element: $this,
                value: data,
                ecn: entityClassName,
                tmpl: false,
                options: $.extend(true , { disp: true, name: o[o.propertyName] }, o)
            });   
        }else{
            var f={ $element: $this, value: data, options: o };
            _setAttrValues(f);
            _setDataValues(f);
        }

        $this.find('[data-'+o.propertyName+']').each(function() {
            var $field = $(this),
                fo = $field.data();
            _disp({ 
                $element: $field,
                value: data,
                ecn: entityClassName,
                tmpl: false,
                options: $.extend(true , { disp: true, name: fo[o.propertyName] }, fo )
            });
    

            // var $field = $(this),
            //     f = {
            //         value: data,
            //         ecn: entityClassName,
            //         tmpl: false,
            //         $element: $field,
            //         options: $.extend(true , { disp: true }, $field.data())
            //     },
            //     fo = f.options;
            // if (o.excludeFields && o.excludeFields.indexOf(fo.name) >= 0) {
            //     return true;
            // }
            // _traversData(f);
            // _setAttrValues(f);
            // _setDataValues(f);
            // if (fo.edit) {
            //     var editValue = B.obj.is(f.value) && f.value.hasOwnProperty('Value') ? f.value.Value : f.value;
            //     if (B.obj.is(editValue)) {
            //         editValue = B.obj.getValue(fo.edit, editValue);
            //     }
            //     if(isSet(editValue)){
            //         $field.writeField(editValue);
            //     }
            // } else {
            //     _checkDiff(f);
            //     if (f.options.disp) {
            //         f.tmpl = f.options.tmpl || o.templates[f.options.tmplName || f.fullname];                   
            //         $field.empty();
            //         if(f.options.prefixContent){
            //             $field.append(f.options.prefixContent);
            //         }
            //         if ($.isArray(f.value)) {
            //             if (f.tmpl) {
            //                 var $c = $(f.tmpl);
            //                 if ($c.data('container') !== undefined) {
            //                     //sprawdzenie czy jest container
            //                     $field.append($c);
            //                     $field = $c;
            //                     f.tmpl = o.templates[f.fullname + '_entry'] || $c.data('tmpl');
            //                 }
            //             }
            //             for (var i = 0, ien = f.value.length; i < ien; i++) {
            //                 _show(f, f.value[i]);
            //             }
            //         } else {
            //             _show(f);
            //         }
            //         _showDiffSignal(f);
            //     }
            // }
            // f.$element[ f.diff ? 'addClass' : 'removeClass' ](B.obj.getValue('diffClass', f.options, o.diffClass));
        });
        return $this;
    };
})(jQuery, Bajt);
