$.widget('bajt.clientService', $.extend(true, {}, Bajt.basicWidget, {
    options: {
        ecn: ECN.CLIENT,
        selectors: {
        },
        panels: {},
        info: {}
    },
    _createWidgetOptions:function(){
        Bajt.basicWidget._createWidgetOptions.call(this);
        this.options.clientIdPrototype = this.getEntitySetting('eid');
        return this;
    },
    _createData: function () {
        B.basicWidget._createData.call(this);
        $.extend(true, this._data, {
            client: null,
            clientId: null

        });
        return this;
    },
    _createControls: function()
    {
        Bajt.basicWidget._createControls.call(this);
        this._createInfo(); 
        this._createPanels(); 
        return this;       
    },
    _createInfo: function(){
        var $info = this.element.find('#client-info');
        this.info={
            $preview: $info.find('.info-box-header'),
            $content: $info.find('.info-box-content'),
            options:  $.extend(true, this.options.info , $info.data())
        };
        return this;
    },
    _createPanels: function () {
        var op = this.options.panels;
        this.panels = {};
        for (var p in op) {
            var $panel=this.element.find('#' + p + '_panel'),
                po = $.extend(true, { ajax: false }, op[p] || {}, $panel.data());     
            this.panels[p] = {
                $panel: $panel,
                options: po
            };
            if (!po.ajax) {
                this.panels[p].$toolbar = $panel.find('#' + p + '_toolbar');
                this.panels[p].table = dataTables[p];
                this.panels[p].filter = filterBars[p];
            }            
        }
        return this;
    },
    _initialize: function(){
        B.basicWidget._initialize.call(this);
        this._showInfo()._actualizeToolbars();
        return this;
    },
    _showInfo: function () {
        var
            templates=this.info.options.templates,
            client = this.getClient();
        if (Bajt.obj.is(client)) {
            var _create$Client=function(tmpl){
                    return $(templates[tmpl]).fill(client, ECN.CLIENT );
                };
            this.info.$preview.empty().append(_create$Client('preview'));
            this.info.$content.empty().append(_create$Client('info'));
        } else {
            this.info.$preview.html(templates.empty);
            this.info.$content.empty();
        }
        return this;
    },
    _actualizeToolbars: function() {
        for (var p in this.panels){
            this._actualizeUrls(this.panels[p].$toolbar);
        }
        return this;
    },
    _actualizeUrls: function ($container){
        if(B.obj.is$($container)){
            var cid = this._data.clientId;
            $container.find('a').each(function(){
                var $a = $(this),
                    url = $a.data('url-tmpl');
                    if (cid) {
                        if (url) {
                            url = B.url.modifyClient(url, cid);
                            $a.attr('href', url).data('url', url);
                        }
                        $a.removeClass('disabled');
                    } else {
                        if (url) {
                            $a.attr('href', '#').data('url', '#');
                        }
                        $a.addClass('disabled');
                    }   
            });
        }
        return this;
    },
    _reloadPanels:function(){
        var activePanel = this._findActivePanel();
        this._reloadPanel(this.panels[activePanel]);
        for (var p in this.panels) {
            if(p != activePanel){
                this._reloadPanel(this.panels[p]);
            }
        }
        return this;
    },
    _findActivePanel:function(){
        var p;
        for (p in this.panels) {
            if(this._isActivePanel(this.panels[p])){
                break;
            }
        }
        return p;
    },
    _isActivePanel:function(panel){
        return panel.$panel.parent().hasClass('active');
    },
    _reloadPanel:function(panel){
        var cid = this._data.clientId;
        if (panel.options.ajax) {
            if (cid) {
                panel.$panel.showLoader();
                panel.$panel.load( this._createClientUrl(panel.options.url.start), function(){
                    panel.$panel.initContent();
                });
            } else {
                panel.$panel.empty();
            }
        } else if (panel.table) {
            var dto = DT.getTableSettings(panel.table);
            DT.setClient(panel.table, cid);
            panel.table.ajax.url(this._createClientUrl(dto.ajax.url)).load();
            this._actualizeUrls(panel.$toolbar);
        }
    },
    _showDetails: function () {
        var
            client = this._data.client,
            o = this.options,
            $details = this.$details;
        if (Bajt.obj.is(client)) {
            $details.showLoader();
            $details.load(this._createClientUrl(o.details.url.start), function () {
                $details.initContent();
            });
        }
        return this;
    },
    _createClientUrl:function(url){
        return url.replace(this.options.clientIdPrototype, this._data.clientId);
    },
    setClient: function (clientData) {
        this._data.client=clientData;
        this._data.clientId = B.obj.getValue('id', clientData);
        this._showInfo();
        this._reloadPanels();
        return this;
    },
    getClient: function () {
        return this._data.client;
    },
    getClientId: function(){
        return this._data.clientId;
    }
}));


$.widget('bajt.clientsService', $.extend(true, {}, Bajt.basicWidget, {
    options: {
        selectors: {
            leftPanel: '#client_panel',
            rightPanel: '#service_panel',
            clientTable: '#client_table'
        }
    },
    _createBasicControls: function () {
        B.basicWidget._createBasicControls.call(this);
        this._createElements(['leftPanel', 'rightPanel', 'clientTable']);
        return this;
    },
    _createData: function () {
        B.basicWidget._createData.call(this);
        $.extend(true, this._data, {
            clientId : 0,
            rowIndex : 0
        } );
        return this;
    },
    _createControls:function(){
        B.basicWidget._createControls.call(this);
        this.service = this.$rightPanel.clientService().data('bajtClientService');
        // this.clientTable = this.$clientTable.DataTable();
        return this;
    },
    _bind: function () {
        B.basicWidget._bind.call(this);
        var that = this;
        this.$clientTable.on({
            'user-select.dt': function ( e, dt, type, cell, originalEvent ) {
                if ( $(cell.node()).hasClass('dt-actions') ) {
                    e.preventDefault();
                }
            },
            'select.dt': function (e, dt, type, indexes) {
                that._selectClient(dt.row(indexes).data());
                // var row = dt.row(indexes).data();
                // console.log('select -' + type + ' - ' + indexes + ' : ' );
                // console.log(row);
            },
            'deselect.dt': function (e, dt, type, indexes) {
                that._selectClient(null);
            }
        });
        
            // .on('deselect', function (e, dt, type, indexes) {
            //     console.log('select -' + type + ' - ' + indexes);
            // })
            // .on('selectItems', function (e, dt, items) {
            // console.log('selectItems');
            // })
            // .on('selectStyle', function (e, dt, style) {
            //     console.log('selectStyle');

            // });

        // this.$clientTable.on('user-select', function (e, dt, type, cell, originalEvent) {
        //     console.log('user-select');
        // });

        //            this.$panelLeft.on('select.dt', function(e, row, element){
        //                that.clientId=row.id;
        //                that.service.client(row);
        //            });


        //            this.$search.on('keypress', function(e){
        //                var charCode = (typeof e.which === 'number') ? e.which : e.keyCode;
        //                switch (charCode) {
        //                    case 0: // Arrow keys, delete, etc
        //                    case 38:
        //                    case 40:
        //                        if (e.keyCode >=37 || e.keyCode <= 40){
        //                            switch (e.keyCode)
        //                            {
        //                                case 38:
        //                                    step=-1;
        //                                break;
        //                                case 40:
        //                                    step=1;
        //                                break;
        //                            }           
        //                            if (step!==0){
        //                                e.preventDefault();
        //                                e.stopPropagation();
        //                                $(this).trigger('scroll', {step: step });
        //                                return false;
        //                            }
        //                        }
        //                        return true;
        //                    case 13:
        //                        e.preventDefault();
        //                        e.stopPropagation();   
        //                        $(this).trigger('choice');
        //                        return false;
        //                }
        //                return true;
        //            });
        //            this._on(this.$panelLeft, { 
        //                scroll: this._scrollClient, 
        //                choice: this._choiceClient  
        //            });
        return this;
    },
    _selectClient:function(clientData){
        this._data.clientId = B.obj.getValue('id', clientData);
        this.service.setClient(clientData);
        return this;
    },
    _scrollClient: function (e, data) {
        var d=this._data;
        if (d.rowIndex + data.step >= 0) {
            d.rowIndex += data.step;
        }
        if (d.rowIndex >= 0) {

        }
        return this;
    },
    // _choiceClient: function (e, data) {
    //     // var $t = this.$clientTable;

    // }

}));
(function ($) {
    $.fn.initClientService = function (options) {
        var $service = $('#service');
        if (Bajt.obj.is$($service)) {
            $service.clientsService();
        }

    };
})(jQuery);