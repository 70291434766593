DT.renders.state = function (data, type, full, meta) {
    var warning = '';
    if (data >= 4 && full.pg_alarm !== undefined && full.pg_alarm > 0) {
        warning = B.html.get('icon', {
            icon: 'warning',
            addClass: 'text-warning',
            attr: {
                title: 'Istnieją pozycje zamówienia nie przyporządkowane do palet: ' + full.pg_alarm
            }

        });
    }
    return warning + DT.renders.dic(data, type, full, meta);
};

DT.renders.productionProcess = function (data, type, full, meta) {
    var $progress = $('<div class="progress"></div>'),
        stages = data.split(',');
    for(var i in stages){
        var d = stages[i].split('#'),
            stage = B.dic.getRow(ECN.PRODUCTIONSTAGE, ECN.PRODUCTIONSTAGE, d[3]),
            $bar = $('<div class="progress-bar bg-progress-' + i + '" role="progressbar" aria-volumemin="0" aria-volumemax="100"></div>');
        $bar.css('width', d[2] + '%');
        $bar.attr('aria-volume-now', d[2]);
        $bar.html(stage.s).attr('title', stage.n);

        $progress.append($bar);            
    }

    return $('<div/>').append($progress).html();
};
