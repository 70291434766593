(function($, B) {
    'use strict';

    // var _n = {
    //     itemChild: {
    //         ecn: ECN.PRODUCTIONSTAGE,
    //         en: B.entity.genName(ECN.PRODUCTIONSTAGE),
    //         field: 'stage'
    //     }  
    // };


    $.widget(
        'bajt.productionProcessStage',
        $.extend(true, {}, B.formCollectionItem, {
            options: {
                ecn: ECN.PRODUCTIONPROCESSSTAGE,
                nrField: 'lp',          
                formFields: B.basicForm._concatFieldsOptions(
                    ['oryginalId', 'progress'],
                    {
                        lp : {
                            options: {
                                disp: {
                                    type: 'v',
                                    addClass: 'text-right'
                                }
                            }
                        },
                        stage: {
                            options: {
                                dictionary: ECN.PRODUCTIONSTAGE,
                                show: {
                                    type: 'dic'
                                }
                            }
                        }
                    }
                )
            }
        })
    );   


    $.widget(
        'bajt.productionProcessStages',
        $.extend(true, {}, B.formCollectionImportDT, {
            options: {
                ecn: ECN.PRODUCTIONPROCESSSTAGE,
                sortable: true,
                focusField: 'progress',
                dtExport:{
                    fields: ['id', 'name']
                },
                itemOptions:{
                    widget: 'productionProcessStage'
                },
                locale: {
                    validate: {
                        collection: 'Etapy produkcji'
                    }
                }
            },

        
            _itemImportData: function(inData) {                
                var out={
                    // value : inData.price
                };
                out.stage = inData.id;
                return out;
            }
        })
    );   

    $.widget(
        'bajt.formProductionProcess',
        $.extend(true, {}, B.basicForm, {
            options: {            
               formFields: B.basicForm._concatFieldsOptions(
                    [ 'name ', 'symbol', 'description'], 
                    {
                        stages: {
                            fieldWidget: 'productionProcessStages'
                        }
                    }
                )
            },
            _bind: function() {
                B.basicForm._bind.call(this);
                return this;
            },
            _createFields: function () {
                return B.basicForm._createFields.call(this);
            },
            _createWidgetOptions:function(){
                return B.basicForm._createWidgetOptions.call(this);
            }

        })
    );

    $.fn.initFormProductionProcess = function() {
        var $form = $(this).find('form[data-form=productionprocess]');
        if (B.obj.is$($form)) {
            $form.formProductionProcess();
        }
    };
})(jQuery, Bajt);

