(function($, B) {
    'use strict';

    
    $.widget('bajt.modalTrims', $.bajt.modalField, {
        options: {
            ecn: ECN.TRIM,
            separator: ', '
        },
        // _createBasicControls: function() {
        //     this._superApply(arguments);
        //     // this.$checks = this.$fieldsContainer.find('input');
        //     return this;
        // },
        _createBasicOptions: function() {
            this._superApply(arguments);
            this.options.dictionary = B.dic.get(ECN.TRIM);
            return this;
        },
        _bind: function() {
            this._superApply(arguments);
            this._on(this.$field, {
                click: this._eChoice
            });
        },
        _fromDictionary: function(value) {
            return B.dic.from(this.options.dictionary, value, {
                name: 's'
            });
        },
        _eChoice: function(e) {
            var that = this,
                $target = $(e.currentTarget),
                checked = $target.is(':checked'),
                selected = this.$field.readField();
            if (checked && selected.length > 1) {
                var choicedSymbol = this._fromDictionary($target.val()),
                    allowed = [choicedSymbol].concat(this.getEntitySetting('combinations')[choicedSymbol] || []);
                that.$field.filter(':checked').each(function() {
                    var $this = $(this),
                        s = that._fromDictionary($this.val());
                    if (allowed.indexOf(s) < 0) {
                        $this.prop('checked', false);
                    }
                });                    
            }

        }
    });


    function initOrderFieldModals(){
        $('[data-modal-widget=field]').modalField();
        $('[data-modal-widget=trims]').modalTrims();
        $('#imp_positions_modal').modalImport();
    }



    var fieldPriceOptions = {
        disp: {
            type: 1,
            empty: '-',
            addClass: 'prices text-right',
            convert: {
                name: 'price',
                options: {
                    sufix: 'zł',
                    tmpl: {
                        name: 'price'
                    }
                }
            }
        },
        show: {
            type: 1,
            empty: '-',
            addClass: 'prices text-right',
            convert: {
                name: 'price',
                options: {
                    sufix: 'zł',
                    tmpl: {
                        name: 'price'
                    }
                }
            }
        }
    };

    function getPredefined(fields, choice){
        var ff = [];
        for(var k in choice){
            ff = ff.concat(fields[choice[k]]);
        }
        return ff;
    }


    var position = {
        fields: {
                basic: [
                    {
                        name: 'oryginalId',
                    },
                    {
                        name: 'nr',
                        options: {
                            disp: {
                                type: 'v',
                                addClass: 'text-right'
                            }
                        }
                    }
                ],
                cmDim: [
                    {
                        name: 'lengthcm',
                        options: {
                            precision: 1,
                            round: 0.5,
                            autocorrect: true,
                            type: 'float',
                            check_key: 1,
                            calc: 1,
                            navi: 1,
                            selectOnFocus: true
                        }
                    },
                    {
                        name: 'widthcm',
                        options: {
                            precision: 1,
                            round: 0.5,
                            autocorrect: true,
                            type: 'float',
                            check_key: 1,
                            calc: 1,
                            navi: 2,
                            selectOnFocus: true
                        }
                    }
                ],
                quantity: [
                    {
                        name: 'quantity',
                        options: {
                            check_key: 1,
                            calc: 1,
                            navi: 3,
                            selectOnFocus: true
                        }
                    }
                ],
                price: [
                    {
                        name: 'price',
                        options: $.extend(true, {
                            calc: 1
                        }, fieldPriceOptions)
                    },
                ],
                parameters: [
                    {
                        name: 'model',
                        options: {
                            required: true,
                            control: {
                                type: 'modal',
                                modal: 1,
                                signal: 2,
                                navi: '1',
                                disp: 'disp'
                            },
                            disp: {
                                type: 'dic.s',
                                empty: 'M',
                            }
                        }
                    },
                    {
                        name: 'color',
                        options: {
                            required: true,
                            control: {
                                type: 'modal',
                                modal: 1,
                                signal: 2,
                                navi: '1',
                                disp: 'disp'
                            },
                            disp: {
                                type: 'dic.s',
                                empty: 'K'
                            }
                        }
                    },
                    {
                        name: 'size',
                        options: {
                            required: true,
                            control: {
                                type: 'modal',
                                modal: 1,
                                signal: 2,
                                navi: 1,
                                disp: 'disp'
                            },
                            disp: {
                                type: 'dic.s',
                                empty: 'G'
                            }
                        }
                    },
                    {
                        name: 'trims',
                        options: {
                            control: {
                                type: 'modal',
                                modal: 1,
                                modalWidget: 'modalTrims',
                                signal: 2,
                                navi: 1,
                                disp: 'disp'
                            },
                            disp: {
                                type: 'dic.s',
                                empty: 'O'
                            }
                        }
                    }               
                ],
                summary: [
                    {
                        name: 'area',
                        options: {                       
                            disp: {
                                type: 1,
                                empty: '-',
                                addClass: 'text-right'
                            },
                            precision: 3
                        }
                    },
                    {
                        name: 'value',
                        options: $.extend(true, {}, fieldPriceOptions)
                    }
                ],
                comments: [
                    {
                        name: 'prodComment',
                        options: {
                            control: {
                                type: 'modal',
                                modal: 'comment',
                                text: 'P',
                                signal: 1,
                                navi: 1
                            }
                        }
                    },
                    {
                        name: 'comment',
                        options: {
                            control: {
                                type: 'modal',
                                modal: 'comment',
                                text: 'K',
                                signal: 1,
                                navi: 1
                            }
                        }
                    },
                    {
                        name: 'clientComment',
                        options: {
                            control: {
                                type: 'modal',
                                modal: 'comment',
                                text: 'W',
                                signal: 1,
                                navi: 1
                            }
                        }
                    }
                ],
                uploads:[
                    {
                        name: 'uploads',
                        options: {
                            control: {
                                signal: 2,
                                navi: '1'
                            }
                        }
                    }
                ]
            }
        },
        order = {
            fields : {
                client: [
                    'client'
                ],
                guestForm: [
                    {
                        name: 'guest',
                        fieldWidget: 'guestForm',
                        locale:{
                            validate: {
                                subForm: 'Dane zamawiającego'
                            }
                        }
                    }
                ],
                clientNumber: [
                    'clientnumber'
                ],
                number: [
                    {
                        name: 'number',
                        options: {
                            disp: {
                                type: 1,
                                empty: '-auto-'
                            }
                        }
                    }
                ],           
                dates: [
                    {
                        name: 'created',
                        options: {
                            type: 'date',
                            format: 'YYYY-MM-DD HH:mm',
                            disp: {
                                type: 1,
                                empty: '-auto-'
                            }
                        }
                    },
                    {
                        name: 'approved',
                        options: {
                            type: 'date',
                            format: 'YYYY-MM-DD HH:mm',
                            disp: {
                                type: 1,
                                empty: '-auto-'
                            }
                        }
                    },
                    {
                        name: 'term',
                        options: {
                            type: 'date',
                            format: 'YYYY-MM-DD',
                            disp: {
                                type: 1,
                                empty: '-auto-'
                            }
                        }
                    }
                ],
                dates_edit: [
                    {
                        name: 'created',
                        options: {
                            type: 'date',
                            format: 'YYYY-MM-DD HH:mm',
                            disp: false,
                            widget: {
                                type: 'datepicker',
                                options: {
                                    autoUpdateInput: false,
                                    locale: {
                                        format: 'YYYY-MM-DD HH:mm'
                                    }
                                },
                            }
                        }
                    },
                    {
                        name: 'approved',
                        options: {
                            type: 'date',
                            format: 'YYYY-MM-DD HH:mm',
                            disp: false,
                            widget: {
                                type: 'datepicker',
                                options: {
                                    autoUpdateInput: false,
                                    locale: {
                                        format: 'YYYY-MM-DD HH:mm',
                                    }
                                }
                            }
                        }
                    },
                    {
                        name: 'term',
                        options: {
                            type: 'date',
                            format: 'YYYY-MM-DD',
                            disp: false,
                            widget: {
                                type: 'datepicker',
                                options: {
                                    timePicker: false,
                                    autoUpdateInput: false,
                                    locale: {
                                        format: 'YYYY-MM-DD',
                                    }
                                }
                            }
                        }
                    }
                ],
                express : [
                    {
                        name: 'express',
                        options: {
                            widget: {
                                type: 'combobox'
                            },
                            dictionary: true
                        }
                    }
                ],
                parameters:[
                    {
                        name: 'model',
                        options: {
                            widget: {
                                type: 'combobox'
                            },
                            control: {
                                type: 'modal',
                                modal: 1,
                                icon: 'library_books',
                                addClass: 'btn-default'
                            },
                            dictionary: true
                        }
                    },
                    {
                        name: 'color',
                        options: {
                            widget: {
                                type: 'combobox'
                            },
                            control: {
                                type: 'modal',
                                modal: 1,
                                icon: 'library_books'
                            },
                            dictionary: true
                        }
                    },
                    {
                        name: 'size',
                        options: {
                            widget: {
                                type: 'combobox'
                            },
                            control: {
                                type: 'modal',
                                modal: 1,
                                icon: 'library_books'
                            },
                            dictionary: true
                        }
                    },
                    {            
                        name: 'trims',
                        options: {
                            disp:{
                                type: 'dic.s'
                            },
                            control: {
                                type: 'modal',
                                modalWidget: 'modalTrims',
                                modal: 1,
                                icon: 'library_books'
                            },
                            dictionary: 'Trim'
                        }
                    }
                ],
                summary: [
                    {
                        name: 'quantity',
                        options: {
                            disp: {
                                type: 1,
                                empty: '-'
                            }
                        }
                    },
                    {
                        name: 'area',
                        options: {
                            show: {
                                type: 1,
                                empty: '-',
                                addClass: 'text-right'
                            },
                            precision: 3
                        }
                    },
                    {
                        name: 'value',
                        options: $.extend(true, {}, fieldPriceOptions)
                    }
                ],
                guestComment:[
                    {
                        name: 'comment',
                        options: {
                            control: false
                        }
                    }
                ],

                comments: [
                    {
                        name: 'prodComment',
                        options: {
                            control: {
                                type: 'modal',
                                modal: 'comment',
                                text: 'P',
                                signal: 1
                            }
                        }
                    },
                    {
                        name: 'comment',
                        options: {
                            control: {
                                type: 'modal',
                                modal: 'comment',
                                text: 'K',
                                signal: 1
                            }
                        }
                    },
                    {
                        name: 'clientComment',
                        options: {
                            control: {
                                type: 'modal',
                                modal: 'comment',
                                text: 'W',
                                signal: 1
                            }
                        }
                    }
                ],
                status: [
                    {
                        name: 'status',
                        options: {
                            disp: {
                                empty: '-',
                                type: 'dic'
                            },
                            dictionary: true
                        }
                    }
                ]
            }
        };
    
    B.orderPosition = $.extend(true, {}, B.formCollectionItem, {
        options: {
            calc: 1,
            dimensionsUnit: 'cm',
            ecn: ECN.POSITION,
            summaryField: ['area', 'value'],
            locale: {
                validate: {
                    item: 'pozycja nr '
                }
            }
        },
        _createBasicOptions:function(){
            B.formCollectionItem._createBasicOptions.call(this);
            this.options.fieldOptions = {
                model: this._paramOptions,
                size: this._paramOptions,
                trims: this._paramOptions,
                color: this._paramOptions
            };
            return this;
        },
        _initialize:function(){
            B.subForm._initialize.call(this);
            this.setNr();
            this.updatePrice();
            this.calc();
            return this;
        },        
        _paramOptions: function(fieldName, options) {
            return {
                initDefault: this.options.initDefault,
                fieldDefault: this.options.parent.field(fieldName),
                dictionary: this.getDictionary(fieldName),
                disp: {
                    tmpl: this.options.templates[fieldName]
                }
            };
        },
        _eChange:function(e, data){               
            var fieldName= B.obj.is(data) && data.field ? data.field.getName() : null;
            switch(fieldName){
                case 'color':
                case 'size':
                    this.updatePrice();
                break;
            }
        },           
        _calc: function() {
            var o = this.options,
                fl = this._fieldsByName['length' + o.dimensionsUnit],
                fw = this._fieldsByName['width' + o.dimensionsUnit],
                dividend = o.dimensionsUnit === 'cm' ? 10000 : 1000000,
                fq = this._fieldsByName.quantity,
                fp = this._fieldsByName.price;
            this.sum = {
                quantity: fq.value(),
                _area: 0,
                area: 0,
                value: 0
            };
            if (fl.status() >= 0 && fw.status() >= 0 && fq.status() >= 0) {
                this.sum._area = fl.value() * fw.value() * this.sum.quantity;
                this.sum.area = this.sum._area / dividend;
                this.sum.value = this.sum.area * fp.value() || 0;
            }
            B.formCollectionItem._calc.call(this);
        },
        getDictionary: function(name) {
            return this.getOrder().getDictionary(name);
        }, 
        getOrder:function(){
            return this.options.parent;
        },
        updatePrice:function(){
            return this.field('price').value(this.getPrice());
        },
        getPrice: function() {
            var color = parseInt(this._fieldsByName.color.getValue()),
                size = parseInt(this._fieldsByName.size.getValue());
            return (!isNaN(color) && !isNaN(size)) 
                ? this.getOrder().getPrice(color, size)
                : null;
        } 
    });

    B.orderPositions = $.extend(true, {}, B.formCollection, {
        options: {
            ecn: ECN.POSITION,
            extraActions: [
                { name:'update_prices', container: 'body' }
            ],
            autoNew: true,
            calc: 1,
            selectors: {
                container: '.card'
            },
            locale: {
                validate: {
                    collection: 'Pozycje zamówienia'
                }
            }
        },
        // _create: function(){
        //     B.formCollection._create.call(this);
        // },
        _eActionUpdatePrices:function(e){
            stopTrigger(e);
            if($.isArray(this._data.items)){
                for(var i in this._data.items){
                    this._data.items[i].updatePrice();
                }
            }
        },
        _itemImportData: function(inData) {                
            var out={
                lengthcm : inData[1],
                widthcm : inData[2],
                quantity : inData[3]
            };
            return out;
        }
    });

    B.orderForm = $.extend(true, {}, B.basicForm, {
        options: {
            pricelists: {
                basic: {},
                actual: {}
            },
            calc: 1,
            dimensionsUnit: 'cm',
            summaryField: ['quantity', 'area', 'value'],
        },
        // _createBasicControls: function() {
        //     B.basicForm._createBasicControls.call(this);
        //     this.$importModal= $(this.options.selectors.importModal);
        //     return this;
        // },
        _initialize:function(){
            B.basicForm._initialize.call(this);
            this._changeTerm();
            return this;
        },            
        _block: function() {
            B.basicForm._block.call(this);
            // $(B.html.validateSelector(this.options.formName + '_client')).prop('disabled', block);
            return this;
        },            
        _calc: function() {
            this.sum=this.field('positions').calc();
            B.basicForm._calc.call(this);
        },
        _summary: function() {
            this.sum=this.field('positions').sum;
            B.basicForm._summary.call(this);
        },
        _getStartDate:function(){
            if(this._fieldsByName.approved && !this._fieldsByName.approved.isEmpty()){
                return this._fieldsByName.approved.value();
            }else if(this._fieldsByName.created && !this._fieldsByName.created.isEmpty()){
                return this._fieldsByName.created.value();            
            }
        },
        _changeTerm:function(startDate){
            startDate = startDate || this._getStartDate();
            var 
                expr = this._fieldsByName.express.value(),
                days = B.dic.from(this.getDictionary('termPeriods'), expr),
                term = startDate ? moment(startDate) : moment();
            if (!isSet(days)) {
                days = (4 - expr) * 7;
            }
            term.add(days, 'days');
            // if (this._fieldsByName.term.formWidget) {
            //     this._fieldsByName.term.formWidget.setStartDate(term);
            // } else {
            //     this._fieldsByName.term.value(term);
            // }
            this._fieldsByName.term.setValue(term);
        },            
        _correctDate:function(field, date, later){
            var fieldVal = field ? field.value() : null;
            if(fieldVal && ((later && fieldVal < date) || (!later && fieldVal > date))){
                field.value(date);
            }
        },
        _correctTerm:function(){
            var startDate = this._getStartDate();
            if(startDate && this._fieldsByName.term.value() < startDate){
                this._changeTerm( startDate);
            }
        },
        _changeFieldExpress:function(data){
            this._changeTerm();
        },
        _changeFieldCreated:function(data){
            this._correctDate(this._fieldsByName.approved, data.field.value(), true);
            this._changeTerm();
        },
        _changeFieldApproved:function(data){
            this._correctDate(this._fieldsByName.created, data.field.value());
            this._changeTerm();
        },
        // _customChange: function(data) {
        //     if (B.obj.is(data.field)) {
        //         var f = data.field,
        //             name = f.option('name');
        //         switch (name) {
        //             case 'express':
        //                 this._changeTerm();
        //             break;
        //             case 'created':
        //                 this._correctDate(this._fieldsByName.approved, f.value(), true);
        //                 this._changeTerm();
        //             break;
        //             case 'approved':
        //                 this._correctDate(this._fieldsByName.created, f.value());
        //                 this._changeTerm();
        //             break;
        //             case 'term':
        //                 this._correctTerm();
        //             break;
        //         }
        //     }
        // },
        // _posActionImport:function(e){
        //     stopTrigger(e);
        //     this.$importModal.modalImport('show', {
        //         form: this,
        //         fnImport: this.importPositions
        //     });
        // },
        _posImportData: function(inData) {
            var outData = {},
                fields = [null, 'lengthcm', 'widthcm', 'quantity'];
            for (var i in inData) {
                if (fields[i]) {
                    outData[fields[i]] = inData[i];
                }
            }
            return outData;
        },
        getPrice:function(color, size, pricelistType){
            return B.obj.getValue([String(color), String(size)], this.options.pricelists[pricelistType || 'actual']);
        }
    });

    $.widget(
        'bajt.orderPosition',
        $.extend(true, {}, B.orderPosition, {
            options: {
                formFields: B.basicForm._concatFieldsOptions(getPredefined(position.fields, ['basic', 'cmDim', 'quantity', 'price', 'parameters', 'summary', 'comments', 'uploads']))
            }
        })
    );

    $.widget(
        'bajt.calculationPosition',
        $.extend(true, {}, B.orderPosition, {
            options: {
                formFields: B.basicForm._concatFieldsOptions(getPredefined(position.fields, ['basic', 'cmDim', 'quantity', 'price', 'parameters', 'summary' ]))
            }
        })
    );

    $.widget(
        'bajt.guestOrderPosition',
        $.extend(true, {}, B.orderPosition, {
            options: {
                formFields: B.basicForm._concatFieldsOptions(getPredefined(position.fields, ['basic', 'cmDim', 'quantity', 'price', 'parameters', 'summary', 'comments']))
                // formFields: [
                //     {
                //         name: 'nr',
                //         options: {
                //             disp: {
                //                 type: 'v',
                //                 tmpl: '<span>__v__</span>'
                //             }
                //         }
                //     },
                //     {
                //         name: 'lengthcm',
                //         options: {
                //             precision: 1,
                //             round: 0.5,
                //             autocorrect: true,
                //             type: 'float',
                //             check_key: 1,
                //             calc: 1,
                //             navi: 1,
                //             selectOnFocus: true
                //         }
                //     },
                //     {
                //         name: 'widthcm',
                //         options: {
                //             precision: 1,
                //             round: 0.5,
                //             autocorrect: true,
                //             type: 'float',
                //             check_key: 1,
                //             calc: 1,
                //             navi: 2,
                //             selectOnFocus: true
                //         }
                //     },
                //     {
                //         name: 'quantity',
                //         options: {
                //             check_key: 1,
                //             calc: 1,
                //             navi: 3,
                //             selectOnFocus: true
                //         }
                //     },
                //     {
                //         name: 'area',
                //         options: {
                //             disp: {
                //                 type: 1,
                //                 empty: '-'
                //             },
                //             precision: 3
                //         }
                //     },
                //     {
                //         name: 'price',
                //         calc: 1,
                //         options: {
                //             disp: {
                //                 type: 1,
                //                 empty: '-'
                //             },
                //             precision: 2
                //         }
                //     },
                //     {
                //         name: 'value',
                //         options: {
                //             disp: {
                //                 type: 1,
                //                 empty: '-'
                //             },
                //             precision: 2
                //         }
                //     },
                //     {
                //         name: 'positionComment',
                //         options: {
                //             control: {
                //                 type: 'modal',
                //                 modal: 'comment',
                //                 text: 'K',
                //                 signal: 1,
                //                 navi: 1
                //             }
                //         }
                //     },
                //     {
                //         name: 'model',
                //         options: {
                //             control: {
                //                 type: 'modal',
                //                 modal: 1,
                //                 text: 'M',
                //                 signal: 2,
                //                 navi: '1'
                //             },
                //             disp: {
                //                 type: 'dic.s',
                //                 empty: 'M'
                //             }
                //         }
                //     },
                //     {
                //         name: 'color',
                //         options: {
                //             control: {
                //                 type: 'modal',
                //                 modal: 1,
                //                 text: 'K',
                //                 signal: 2,
                //                 navi: '1'
                //             },
                //             disp: {
                //                 type: 'dic.s',
                //                 empty: 'K'
                //             }
                //         }
                //     },
                //     {
                //         name: 'size',
                //         options: {
                //             control: {
                //                 type: 'modal',
                //                 modal: 1,
                //                 text: 'G',
                //                 signal: 2,
                //                 navi: 1
                //             },
                //             disp: {
                //                 type: 'dic.s',
                //                 empty: 'G',
                //             }
                //         }
                //     },
                //     {
                //         name: 'trims',
                //         options: {
                //             control: {
                //                 type: 'modal',
                //                 modal: 1,
                //                 modalWidget: 'modalTrims',
                //                 signal: 2,
                //                 navi: 1
                //             },
                //             disp: {
                //                 type: 'dic.s',
                //                 empty: 'O'
                //             }
                //         }
                //     },
                //     // {
                //     //     name: 'trims',
                //     //     options: {
                //     //         control: {
                //     //             type: 'modal',
                //     //             modal: 1,
                //     //             modalWidget: 'modalTrims',
                //     //             text: 'O',
                //     //             signal: 2,
                //     //             navi: '1'
                //     //         },
                //     //         disp: {
                //     //             type: 'dic.s',
                //     //             empty: 'O',
                //     //             prefix: 'btn'
                //     //         },
                //     //         dictionary: 'Trims'
                //     //     }
                //     // },
                //     {
                //         name: 'uploads',
                //         options: {
                //             control: {
                //                 signal: 2,
                //                 navi: '1'
                //             }
                //         }
                //     }
                // ]
            },
            _initialize:function(){
                B.orderPosition._initialize.call(this);
                return this;
            },    
        })
    );
    
    $.widget(
        'bajt.orderPositions',
        $.extend(true, {}, B.orderPositions, {
        })
    );
    
    $.widget(
        'bajt.calculationPositions',
        $.extend(true, {}, B.orderPositions, {
        })
    );
    
    $.widget(
        'bajt.order',
        $.extend(true, {}, B.orderForm, { 
            options: {
                copyTextarea: '#orders_exp_copy',
                expBtns: true,
                locale: {
                    validate:{
                        form: 'Sprawdzanie formularza zamówienia'
                    }
                },
                formFields: B.basicForm._concatFieldsOptions(
                    getPredefined(order.fields, ['client', 'clientNumber', 'number', 'dates_edit', 'express', 'parameters', 'summary', 'comments', 'status' ]),
                    [
                        {
                            name: 'positions',
                            fieldWidget: 'orderPositions',
                            options:{
                                focusField: 'lengthcm',
                                itemOptions:{
                                    dimensionsUnit: 'cm',
                                    widget: 'orderPosition'
                                }
                            }
                        }
                    ]
                ),
                statusButtons: {
                    prev: {
                        2: {
                            label: 'Edycja',
                            title: 'Edycja',
                            addClass: '',
                            icon: 'reply'
                        },
                        3: {
                            label: 'Do klienta',
                            title: 'Do klienta',
                            addClass: '',
                            icon: 'reply'
                        }
                    },
                    next: {
                        1: {
                            label: 'Zatwierdź',
                            title: 'Zatwierdź',
                            addClass: '',
                            icon: 'send'
                        },
                        2: {
                            label: 'Obsługa',
                            title: 'Obsługa',
                            addClass: '',
                            icon: 'edit'
                        }
                    }
                }
            },
            _blockPartial: function() {
                if (this._status === 2 || this._status > 3) {
                    var noBlock = {
                            2: ['status', 'approved'],
                            3: ['status']
                        },
                        nb = noBlock.hasOwnProperty(this._status) ? noBlock[this._status] : [];
                    this._blockFields(true, B.obj.arrayDiff(Object.keys(this._fieldsByName), nb));
                    this._blockFields(false,  nb);
                    this._blockPositions(true);
                    $(B.html.validateSelector(this.options.formName + '_client')).prop('disabled', true);
                }
            },
            _checkStatus: function() {
                // if(that._fieldsByName['status'].value() == 2 && posit.servicesCount == 0)
                // that._fieldsByName['status'].value(1);
            },
            _customAllowedOperation: function(operation, data) {
                var allow = true;
                switch (operation) {
                    case 'prevStatus':
                        allow = this._status === 2 || this._status === 3;
                        break;
                    case 'nextStatus':
                        allow =
                            !this.field('created').isEmpty() &&
                            this._status < 3 &&
                            (this._status === 2 || this.field('approved').isEmpty());
                        break;
                }
                return allow;
            },
            _customState: function(state, data) {
                switch (state) {
                    case 'start':
                    case 'normal':
                    case 'submitSuccess':
                        this._blockPartial();
                        break;
                }
                return this._state;
            },
            _statusActionNext: function(e) {
                this._statusSet(this._status+1);
            },
            _statusActionPrev: function(e) {
                this._statusSet(this._status - 1);
            }
        })
    );

    $.widget(
        'bajt.guestOrder',
        $.extend(true, {}, B.orderForm, { 
            options: {
                expBtns: true,
                locale: {
                    validate:{
                        form: 'Sprawdzanie formularza zamówienia'
                    }
                },
                formFields: B.basicForm._concatFieldsOptions(
                    getPredefined(order.fields, ['guestForm', 'number', 'dates', 'express', 'parameters', 'summary', 'guestComments', 'status' ]),
                    {
                        positions: {
                            name: 'positions',
                            fieldWidget: 'orderPositions',
                            options:{
                                focusField: 'lengthcm',
                                itemOptions:{
                                    dimensionsUnit: 'cm',
                                    widget: 'guestOrderPosition'
                                }
                            }
                        }
                    }
                )
            }
        })
    );

    $.widget(
        'bajt.guestForm',
        $.extend(true, {}, B.subForm, { 
            options: {
                locale: {
                    validate: {
                        item: 'Dane zamawiającego'
                    }
                }
            },
            validate: function() {
                this.valid.init();
                this._validateFields();
                return this.valid;
            }
        })
    );

    $.widget(
        'bajt.calculation',
        $.extend(true, {}, B.orderForm, { 
            options: {
                expBtns: true,
                locale: {
                    validate:{
                        form: 'Sprawdzanie formularza kalkulacji'
                    }
                },
                formFields: B.basicForm._concatFieldsOptions(
                    getPredefined(order.fields, ['client', 'dates', 'express', 'parameters', 'summary']),
                    [
                        {
                            name: 'positions',
                            fieldWidget: 'calculationPositions',
                            options:{
                                focusField: 'lengthcm',               
                                itemOptions:{
                                    dimensionsUnit: 'cm',
                                    widget: 'calculationPosition'
                                }
                            }
                        },
                        'email',
                        'allegro'    
                    ]
                )
            }
        })
    );

    $.fn.initFormOrder = function() {
        var $form = $(this).find('form[data-form=productsorder]');
        if (B.obj.is$($form)) {
            $form.order();
            initOrderFieldModals();
        }
    };

    $.fn.initFormGuestOrder = function() {
        var $form = $(this).find('form[data-form=guest_productsorder]');
        if (B.obj.is$($form)) {
            $form.guestOrder();
            initOrderFieldModals();
        }
    };

    $.fn.initFormCalculation = function() {
        var $form = $(this).find('form[data-form=calculation]');
        if (B.obj.is$($form)) {
            $form.calculation();
            initOrderFieldModals();
        }
    };


})(jQuery, Bajt);
