(function($, B) {
    'use strict';

    $.widget(
        'bajt.orderItem',
        $.extend(true, {}, B.formCollectionItem, {
            options: {
                ecn: ECN.ORDER,
                // uniqueField: 'productsOrder',
                // actionPrefix: 'orders',
            },
            getUnique: function() {
                var order=this.field('order').value();
                return order.id || 'unique_error';
            },            
            _createControls: function() {
                B.formCollectionItem._createControls.call(this);
                return this;
            },
        })
    );   

    
    $.widget(
        'bajt.choiceOrders',
        $.extend(true, {}, B.formCollectionImportDT, {
            options: {
                // ecn: ECN.ORDER,
                // actionPrefix: 'productsorders',
                locale: {
                    validate: {
                        collection: 'Zamówienia'
                    }
                },
                selectors: {
                    container: '.modal-body',
                },
                // focusField: 'value',               
                dtExport:{
                    fields: ['id', 'number', 'approved', 'term', 'express', 'quantity', 'area', 'client.name', 'client.code']
                },
                unique: {
                    on: true,
                    showFilter: true,
                    filter: true
                },
                itemOptions:{
                    widget: 'orderItem'
                }
            },
            _itemImportData: function(inData) {                
                var out={
                    order : inData
                };
                return out;
            }
        })
    );   

    var orderwork={
            options: {
                expBtns: true
            },
            _setOrdersFilter:function(value){
                this.field('productsOrders').setDTFilter(value);
            },      

            // calc: function() {
            //     return this.summary('calc');
            // },
            // summary: function(fn) {
            //     var o = this.options,
            //         dividend = o.dimensionsUnit === 'cm' ? 10000 : 1000000,
            //         sum = {
            //             quantity: 0,
            //             _area: 0,
            //             area: 0
            //         };
            //     fn = fn ? fn : 'getSummary';
            //     for (var p in this._positions) {
            //         var positions = this._positions[p].tab;
            //         for (var i in positions) {
            //             B.obj.summary(sum, positions[i][fn]());
            //         }
            //     }
            //     this._fieldsByName.area.value(sum._area / dividend);
            //     this._fieldsByName.quantity.value(sum.quantity);
            //     return sum;
            // }
        };

   
    $.widget(
        'bajt.formProduction',
        $.extend(true, {}, B.basicForm, orderwork, {
            options:{
                positionsTableFilter: 'production',
                formFields: {
                    number: {
                        options: {
                            disp: {
                                type: 1,
                                default: '-auto-'
                            }
                        }
                    },
                    generated: {
                        options: {
                            type: 'date',
                            format: 'YYYY-MM-DD HH:mm',
                            disp: false,
                            widget: {
                                type: 'datepicker',
                                options: {
                                    autoUpdateInput: false,
                                    locale: {
                                        format: 'YYYY-MM-DD HH:mm'
                                    }
                                },
                            },
                        }
                    },
                    progress: {
                        options: {
                            disp: {
                                type: 1,
                                empty: '-auto-'
                            }
                        }
                    },
                    productsOrders: {
                        fieldWidget: 'choiceOrders',
                        options : {
                            dataTableFilter : 'production'
                        }
                    }                    
                },
                labels: {
                    form: 'Produkcja'
                },
                selectors: {
                    positionsTable: '#position_table'
                }
            },
            _createBasicControls: function() {
                B.basicForm._createBasicControls.call(this);
                this._createElements(['positionsTable']);
                return this;
            },    
            _customState: function(state, data) {
                var o = this.options;
                switch (state) {
                    case 'start':
                        if(this.entityId){
                            this._setOrdersFilter(this.entityId);
                        }
                        break;
                    case 'submitSuccess':
                        this._setOrdersFilter(this.entityId);
                        if(o.positionsTableFilter){
                            DT.setFilter(this.$positionsTable.DataTable(), o.positionsTableFilter, this.entityId );
                        }else{                        
                            this.$positionTable.DataTable().ajax.reload();
                        }
                        break;
                }
            },
            _blockPartial: function() {
                var k= 1;
                // this.orders.block(true);
                return this;
            },
        })
    );
    
    $.widget(
        'bajt.formDelivery',
        $.extend(true, {}, B.basicForm, orderwork, {
            options:{
                productsTableFilter: 'hiddenDelivery'

            }
        })
    );

    $.fn.initFormProduction = function() {
        var $form = $(this).find('form[data-form=production]');
        if (B.obj.is$($form)) {
            $form.formProduction();
        }
    };

    $.fn.initFormDelivery = function() {
        var $form = $(this).find('form[data-form=deliveries]');
        if (B.obj.is$($form)) {
            $form.formDelivery();
        }
    };
})(jQuery, Bajt);
