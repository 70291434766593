(function($, B) {
    'use strict';


    $.widget(
        'bajt.priceListItemsGenerateForm',        
        $.extend(true, {}, B.basicForm, {
            options: {
                actions: ['generate'],
                selectors: {
                    actionsContainer: null
                },
                formFields: {
                    colors: {
                        options: {
                            widget: {
                                type: 'multiselect'
                            }
                        }
                    },
                    sizes: {
                        options: {
                            widget: {
                                type: 'multiselect'
                            }
                        }
                    },
                    items: {
                        fieldWidget: 'priceListItemsCollection',
                        options:{
                            unique: {
                                on: true
                            }
            
                       } 
                    }
                }
            },
            _eActionGenerate:function(e, data){
                stopTrigger(e);
                this._generateItems();
            },
            _generateItems: function(e) {
                var sizes = this.field('sizes').value(),
                    colors = this.field('colors').value();
                if (B.obj.is(sizes) && B.obj.is(colors)) {
                    var items=[];
                    for (var j in sizes) {
                        for (var i in colors) {
                            items.push({
                                color: colors[i],
                                size: sizes[j]
                            });
                        }
                    }
                    this.field('items').import(items);
                }
            }
        })
    );

    $.widget(
        'bajt.priceListItemsCollection',
        $.extend(true, {}, B.formCollection, {
            options: {
                ecn: ECN.PRICELISTITEM,
                autoNew: false,
                focusField: 'symbol',
                itemOptions:{
                    widget: 'priceListItem'
                }                
            },  
            _createBasicOptions:function(){
                $.extend(true, this.options, { 
                    nameGenerator : this.getEntitySetting('nameGenerator'),
                    symbolGenerator : this.getEntitySetting('symbolGenerator')
                });
                return B.formCollection._createBasicOptions.call(this);
            },         
            _genText:function(generator, values){
                return B.genNumber(generator, '', { entity: values, fields: this.options.ecn });
            },
            _genNumberValues:function(values){
                return {
                    size: B.dic.find(this.getDictionary('size'), values.size),
                    color: B.dic.find(this.getDictionary('color'), values.color)
                };
            },
            _genName:function(values){
                var numberValues = this._genNumberValues(values);
                return B.obj.is(this.options['nameGenerator'])
                    ? this._genText(this.options['nameGenerator'], numberValues)
                    : 'Parapet ' + numberValues.color.s + ' ' + numberValues.size.s;
            },
            _genSymbol:function(values){
                var numberValues = this._genNumberValues(values);
                return B.obj.is(this.options['symbolGenerator'])
                    ? this._genText(this.options['symbolGenerator'], numberValues)
                    : numberValues.color.s + '_' + numberValues.size.s;
            },
            _itemImportData: function(inData) {
                var out={
                    color : inData.color,
                    size : inData.size,
                    price : 0                   
                };
                out['name']=this._genName(out);
                out['symbol']=this._genSymbol(out);
                return out;
            }
        })
    );

    $.widget(
        'bajt.priceListItem',
        $.extend(true, {}, B.formCollectionItem, {
            options: {
                ecn: ECN.PRICELISTITEM,
                formFields: [
                    {
                        name: 'color',
                        options: {
                            dictionary: true,
                            disp: {
                                type: 'dic.n',
                            }
                        }
                    },
                    {
                        name: 'size',
                        options: {
                            dictionary: true,
                            disp: {
                                type: 'dic.n',
                            }
                        }
                    },
                    {
                        name: 'symbol',
                        options: {
                            navi: 1
                        }
                    },
                    {
                        name: 'name',
                        options: {
                            navi: 2
                        }
                    },
                    {
                        name: 'price',
                        options: {
                            precision: 2,
                            autocorrect: true,
                            type: 'float',
                            check_key: 1,
                            navi: 3,
                            selectOnFocus: true,
                            widget: {
                                type: 'nettoinput'
                            },
                        }
                    }
                ]
            },
            getUnique:function(){
                return this._fieldsByName.size.value() + '_' + this._fieldsByName.color.value();
            }
        })
    );

    $.widget(
        'bajt.priceListItemsForm',        
        $.extend(true, {}, B.formMultiEdit, {
            options: {
                formFields: {
                    items: {
                        fieldWidget: 'priceListItemsCollection'
                    }
                }
            }
        })
    );

    $.fn.initFormPriceListItemsGenerate = function() {
        var $form = $(this).find('form[data-form=pricelistitemgenerate]');
        if (B.obj.is$($form)) {
            $form.priceListItemsGenerateForm();
        }
    };

    $.fn.initFormPriceListItemsCollection = function() {
        var $form = $(this).find('form[data-form=pricelistitemcollection]');
        if (B.obj.is$($form)) {
            $form.priceListItemsForm();
        }
    };

})(jQuery, Bajt);