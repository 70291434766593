(function($, B) {
    'use strict';
    $.widget(
        'bajt.offer',
        $.extend(true, {}, B.basicWidget, { 
            options: {
            },
            _build:function(){
                return B.basicWidget._build.call(this);
            },
            _initialize:function(){
                console.log('test');
                console.log(this.items);
                for(var i in this.items){
                    this._$itemAdd(this.items[i]);
                }
                return B.basicWidget._initialize.call(this);
            },
            _$itemAdd:function(data){
                var o=this.options,
                    $item=$(o.prototypes.item);
                $item.fill(B.entity.fill(data, o.ecn)).appendTo(this.$container);
                if(o.animate){
                    $item.slideDown(o.animate);
                }else{
                    $item.show();
                }
            }
        })
    );

    $.fn.initOffer = function() {
        var $form = $(this).find('#offer');
        if (B.obj.is$($form)) {
            $form.offer();
        }
    };
})(jQuery, Bajt);
